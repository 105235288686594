import React, { useState } from "react";
import { UploadIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

const FileUpload = ({
  uploadFunc,
  accept = "",
  fileUrls = [],
  handleDeleteFunc,
  setIsReady,
  label,
}: {
  uploadFunc: any;
  accept?: string;
  fileUrls: string[];
  handleDeleteFunc: any;
  setIsReady?: any;
  label?: string;
}) => {
  const [isUploading, setIsUploading] = useState(false);

  // restricting the file types to only images, pdf, excel, word docs for security
  const INIT_ACCEPT_FILE_TYPES =
    "image/jpg,image/jpeg,image/png,image/svg+xml,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain";

  const handleFile = async (e: any) => {
    if (!!setIsReady) {
      setIsReady(false);
    }
    setIsUploading(true);
    const files = e.target.files;
    await uploadFunc(files);
    setIsUploading(false);
    setIsReady(true);
  };

  return (
    <>
      <ul
        role="list"
        className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-4 my-4"
      >
        {fileUrls?.map((url) => (
          <li key={url} className="relative p-1">
            <SingleFileItem url={url} handleDeleteFunc={handleDeleteFunc} />
          </li>
        ))}
        <li className="relative p-1">
          <label
            className="static cursor-pointer border-2 border-gray-300 border-dashed rounded-lg block w-full bg-white relative"
            style={{ paddingBottom: "75%" }} // 4:3 aspect ratio
          >
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none rounded-lg flex items-center justify-center">
              {isUploading ? (
                <span className="inline-flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-indigo-500 -ml-1 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="text-sm font-medium text-gray-900">
                    Uploading
                  </span>
                </span>
              ) : (
                <span className="inline-flex items-center">
                  <UploadIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="text-sm font-medium text-gray-900">
                    {label || "Upload Files"}
                  </span>
                </span>
              )}
              <input
                name="files"
                type="file"
                className="sr-only"
                onChange={handleFile}
                multiple
                accept={accept || INIT_ACCEPT_FILE_TYPES}
              />
            </div>
          </label>
        </li>
      </ul>
    </>
  );
};

export default FileUpload;

const SingleFileItem = ({
  url,
  handleDeleteFunc,
}: {
  url: string;
  handleDeleteFunc: any;
}) => {
  return (
    <div
      className="static cursor-pointer border-2 border-gray-300 border-dashed rounded-lg block w-full bg-white relative"
      style={{ paddingBottom: "75%" }} // 4:3 aspect ratio
    >
      <img
        src={url}
        alt="summary image"
        className="absolute top-0 left-0 w-full h-full object-cover object-center pointer-events-none rounded-lg"
      />
      <XIcon
        onClick={() => handleDeleteFunc(url)}
        className="h-6 w-6 absolute right-0 top-0 text-white cursor-pointer bg-black rounded-bl-lg rounded-tr-lg opacity-50 hover:opacity-100"
      />
    </div>
  );
};
