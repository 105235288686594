import Routes from "./routes/Routes";
import { ToastProvider } from "react-toast-notifications";

export default function App() {
  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <Routes />
    </ToastProvider>
  );
}
