import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";

export const signOut = () => {
  localStorage.removeItem("code");
  localStorage.removeItem("codeExpiry");
};

export const getCodeCache = (ignoreExpiry?: boolean) => {
  if (ignoreExpiry) {
    return localStorage.getItem("code");
  }
  const codeExpiry = localStorage.getItem("codeExpiry");
  const now = dayjs().unix();
  if (!codeExpiry || +codeExpiry < now) {
    // localStorage.removeItem("code");
    // localStorage.removeItem("codeExpiry");
    throw Error("the code is expired");
  } else {
    return localStorage.getItem("code");
  }
};

export const uploadFile = async ({
  hotelId,
  file,
  folder,
}: {
  hotelId: string;
  file: any;
  folder: string;
}) => {
  const { name, type = "", size = 0 } = file;
  const extension = String(file.name).split(".").pop() || "pdf";
  const today = dayjs().format("YYYY-MM-DD");
  const location = `hotels/${hotelId}/${folder}/${today}/${uuid()}.${extension}`;

  // const location = `hotels/${hotelId}/onlinecheckin/${uuid()}.${extension}`;
  // const snapshot = await storageRef.child(location).put(file);
  // const url = await storageRef
  //   .child(snapshot.metadata.fullPath)
  //   .getDownloadURL();
  const fileRef = ref(storage, location);
  const snapshot = await uploadBytes(fileRef, file);
  const url = await getDownloadURL(snapshot.ref);
  const attachment = {
    filename: name,
    path: url,
    type,
    storageReference: location,
    size,
    url,
  };
  return attachment;
};
