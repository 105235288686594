import dayjs from "dayjs";

export const getCurrentYear = () => dayjs().year();
export const getCurrentMonth = () => dayjs().month() + 1;

export const nextTenYearsArr = Array(11)
  .fill(0)
  .map((_, i) => Number(getCurrentYear()) + i);

export const getCCExpiryYear = (fourDigitYear: number | string): string =>
  String(fourDigitYear).slice(-2);
