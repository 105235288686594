import React from "react";

function Loading({ loadingText }: { loadingText?: string }) {
  return (
    <div className="fixed inset-0 h-screen w-screen bg-white oppacity-80 flex justify-center items-center z-50">
      <div className="text-center">
        <svg
          className="animate-spin h-20 w-20 text-indigo-500 mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {typeof loadingText === "string" && (
          <p className="mt-10 text-indigo-500 font-medium">{loadingText}</p>
        )}
      </div>
    </div>
  );
}

export const LoadingContent = ({ loadingText }: { loadingText?: string }) => {
  return (
    <div className="py-40">
      <svg
        className="animate-spin h-10 w-10 text-indigo-500 mx-auto"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {typeof loadingText === "string" && (
        <p className="mt-10 text-indigo-500 font-medium">{loadingText}</p>
      )}
    </div>
  );
};

export default Loading;
