import dayjs from "dayjs";
import { useState, useEffect, useContext } from "react";
import Modal, { ModalFooter, ModalHeader } from "../components/Modal";
import { Input, Button } from "../components";
import { cancelReservation, createReservation } from "../action/actionOwner";
import { PropertyContext } from "../context/property";
import useSubmitWithToast from "../action/useSubmitWithToast";
import { Popover } from "@headlessui/react";
import {
  CalendarIcon,
  ArrowCircleRightIcon,
  MailIcon,
  UserGroupIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import DatePicker from "../components/DatePicker";
import { displayDate } from "../action";

const adultsOptions = Array(16)
  .fill("f")
  .map((_, index) => String(index + 1));

const childrenOptions = Array(15)
  .fill("f")
  .map((_, index) => String(index));

const ReservationModal = ({
  isOpen,
  setOpen,
  room,
  account,
  handleReservations,
}: {
  isOpen: boolean;
  setOpen: any;
  room: any;
  account: any;
  handleReservations: any;
}) => {
  const { propertyId, accountId } = useContext(PropertyContext);
  const INIT = {
    firstName: "",
    lastName: "",
    roomId: "",
    checkIn: "",
    checkOut: "",
    adults: 2,
    children: 0,
    mobile: "",
    email: "",
    address: "",
    city: "",
    state: "",
    postCode: "",
    country: "",
    comments: "",
  };
  const [reservation, setReservation] = useState(INIT);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { roomId = "" } = room || {};
    const {
      label = "",
      mobile = "",
      email = "",
      address = "",
      city = "",
      state = "",
      postCode = "",
      country = "",
    } = account || {};
    setReservation({
      ...INIT,
      roomId,
      mobile,
      email,
      address,
      city,
      state,
      postCode,
      country,
      firstName: label,
      lastName: "OWNER",
    });
    setErrors({});
  }, [isOpen]);

  const handleInput = (e: any) =>
    setReservation({ ...reservation, [e.target.name]: e.target.value });

  const { firstName, lastName, checkIn, checkOut, adults } = reservation || {};

  const validateReservation = () => {
    const error2: any = {};

    if (!firstName) {
      error2.firstName = "Required";
    }

    if (!lastName) {
      error2.lastName = "Required";
    }

    if (!checkIn) {
      error2.checkIn = "Required";
    }

    if (!checkOut) {
      error2.checkOut = "Required";
    }

    if (!dayjs(checkOut).isAfter(checkIn)) {
      error2.checkOut = "Check out date must be greater than check in date.";
    }

    if (!(+adults >= 1)) {
      error2.adults = "Required";
    }

    setErrors(error2);
    const isInvalid = Object.keys(error2).length > 0;

    return isInvalid;
  };

  const [handleSubmit] = useSubmitWithToast({
    func: async () => {
      const isInvalid = validateReservation();
      if (!isInvalid) {
        const code = localStorage.getItem("code") || "";
        return createReservation({
          reservation,
          propertyId,
          ownerId: accountId,
          code,
        });
      } else {
        throw Error("Some fields are not correct.");
      }
    },
    successFunc: () => {
      setOpen(false);
      handleReservations();
    },
    successMessage: "The reservation is confirmed.",
  });

  return (
    <Modal open={isOpen} setOpen={setOpen} size="lg">
      <ModalHeader onClose={() => setOpen(false)}>
        Create Reservation
      </ModalHeader>
      <Reservation
        room={room}
        account={account}
        reservation={reservation}
        handleInput={handleInput}
        errors={errors}
        setReservation={setReservation}
      />
      <ModalFooter>
        <Button onClick={handleSubmit}>Create Reservation</Button>
      </ModalFooter>
    </Modal>
  );
};

function Reservation({
  room,
  account,
  reservation,
  handleInput,
  errors,
  setReservation,
}: {
  room: any;
  account: any;
  reservation: any;
  handleInput: any;
  errors: any;
  setReservation: any;
}) {
  const {
    firstName,
    lastName,
    roomId,
    checkIn,
    checkOut,
    adults,
    children,
    mobile,
    email,
    address,
    city,
    state,
    postCode,
    country,
    comments,
  } = reservation || {};
  const { label: roomName } = room || {};

  const handleBookFor = (e: any) => {
    const val = e.target.value;
    if (val === "owner") {
      const {
        label = "",
        mobile = "",
        email = "",
        address = "",
        city = "",
        state = "",
        postCode = "",
        country = "",
      } = account || {};
      setReservation({
        ...reservation,
        firstName: label,
        lastName: "OWNER",
        mobile,
        email,
        address,
        city,
        state,
        postCode,
        country,
      });
    }
    if (val === "friends") {
      setReservation({
        ...reservation,
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        address: "",
        city: "",
        state: "",
        postCode: "",
        country: "",
        roomId,
      });
    }
  };

  const updateReservation = (state: any) =>
    setReservation((val: any) => ({ ...val, ...state }));

  return (
    <form>
      <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-6 xl:grid-cols-12 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="roomId"
            className="block text-sm font-medium text-gray-700"
          >
            Room
          </label>
          <div className="mt-2">{roomName}</div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="bookFor"
            className="block text-sm font-medium text-gray-700"
          >
            Book for
          </label>
          <div className="mt-2">
            <Input
              type="select"
              name="bookFor"
              id="bookFor"
              onChange={handleBookFor}
            >
              <option value="owner">Book for owner(s)</option>
              <option value="friends">Book for friends & family</option>
            </Input>
          </div>
        </div>

        <div className="col-span-6">
          <label
            htmlFor="checkIn"
            className="block text-sm font-medium text-gray-700"
          >
            Check In / Out
          </label>
          <div className="mt-2">
            <Popover className="relative z-50 block text-left">
              {({ open }) => (
                <>
                  <Popover.Button
                    as="div"
                    className={`relative rounded-md shadow-sm ${
                      open
                        ? "border-2 border-theme-500"
                        : "border border-gray-300"
                    } py-2 cursor-pointer`}
                  >
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <CalendarIcon
                        className={`h-5 w-5 ${
                          open ? "text-theme-500" : "text-gray-400"
                        }`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="block w-full pl-10 sm:text-sm border-gray-300 rounded-md">
                      <div className="flex">
                        {checkIn ? dayjs(checkIn).format("DD/MM/YYYY") : "--"}
                        <div className="mx-2 flex items-center">
                          <ArrowCircleRightIcon
                            className="block h-5 w-5 text-gray-300"
                            aria-hidden="true"
                          />
                        </div>
                        {checkOut ? dayjs(checkOut).format("DD/MM/YYYY") : "--"}
                      </div>
                    </div>
                  </Popover.Button>
                  <Popover.Panel className="z-999">
                    {({ close }) => (
                      <DatePicker
                        startDate={checkIn}
                        setStartDate={updateReservation}
                        endDate={checkOut}
                        setEndDate={updateReservation}
                        onCompleteSelectRange={() => {
                          close();
                        }}
                        isAlwaysOpen={false}
                      />
                    )}
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>

          {/* <Input
            type="date"
            name="checkIn"
            id="checkIn"
            value={checkIn}
            min={dayjs().format("YYYY-MM-DD")}
            onChange={handleInput}
            errors={errors}
          /> */}
        </div>

        {/* <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="checkOut"
            className="block text-sm font-medium text-gray-700"
          >
            Check Out
          </label>
          <Input
            type="date"
            name="checkOut"
            id="checkOut"
            value={checkOut}
            min={dayjs(checkIn).add(1, "days").format("YYYY-MM-DD")}
            className="mt-1"
            onChange={handleInput}
            errors={errors}
          />
        </div> */}

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700"
          >
            First name
          </label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            className="mt-1"
            value={firstName}
            onChange={handleInput}
            errors={errors}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="last-name"
            className="block text-sm font-medium text-gray-700"
          >
            Last name
          </label>
          <Input
            type="text"
            name="lastName"
            id="lastName"
            className="mt-1"
            value={lastName}
            onChange={handleInput}
            errors={errors}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="adults"
            className="block text-sm font-medium text-gray-700"
          >
            Adults
          </label>
          <Input
            name="adults"
            id="adults"
            className="mt-1"
            type="select"
            value={adults}
            onChange={handleInput}
            errors={errors}
          >
            {adultsOptions.map((val) => (
              <option value={val}>{val}</option>
            ))}
          </Input>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="children"
            className="block text-sm font-medium text-gray-700"
          >
            Children
          </label>
          <Input
            name="children"
            id="children"
            className="mt-1"
            type="select"
            value={children}
            onChange={handleInput}
          >
            {childrenOptions.map((val) => (
              <option value={val}>{val}</option>
            ))}
          </Input>
        </div>

        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="mobile"
            className="block text-sm font-medium text-gray-700"
          >
            Mobile
          </label>
          <Input
            type="text"
            name="mobile"
            value={mobile}
            onChange={handleInput}
          />
        </div>

        <div className="col-span-6 sm:col-span-6">
          <label
            htmlFor="email-address"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={handleInput}
          />
        </div>

        <div className="col-span-6">
          <label
            htmlFor="street-address"
            className="block text-sm font-medium text-gray-700"
          >
            Address
          </label>
          <Input
            type="text"
            name="address"
            value={address}
            onChange={handleInput}
          />
        </div>

        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            City
          </label>
          <Input type="text" name="city" value={city} onChange={handleInput} />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="state"
            className="block text-sm font-medium text-gray-700"
          >
            State
          </label>
          <Input
            type="text"
            name="state"
            value={state}
            onChange={handleInput}
          />
        </div>

        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label
            htmlFor="postal-code"
            className="block text-sm font-medium text-gray-700"
          >
            Post Code
          </label>
          <Input
            type="text"
            name="postCode"
            value={postCode}
            onChange={handleInput}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="country"
            className="block text-sm font-medium text-gray-700"
          >
            Country
          </label>
          <Input
            type="select"
            name="country"
            value={country}
            onChange={handleInput}
          >
            <option value="AU">Australia</option>
            <option value="NZ">New Zealand</option>
          </Input>
        </div>

        <div className="col-span-12 sm:col-span-12 lg:col-span-12">
          <label
            htmlFor="comments"
            className="block text-sm font-medium text-gray-700"
          >
            Comments
          </label>
          <Input
            type="textarea"
            name="comments"
            rows={10}
            value={comments}
            onChange={handleInput}
          />
        </div>
      </div>
    </form>
  );
}

export const CancelReservationButton = ({
  reservationId,
  reservation,
  handleReservations,
}: {
  reservationId: string;
  reservation: any;
  handleReservations: any;
}) => {
  const [isOpen, setOpen] = useState(false);
  const { propertyId, accountId } = useContext(PropertyContext);

  const {
    checkIn,
    checkOut,
    firstName,
    lastName,
    adults,
    children,
    email,
    ref,
  } = reservation;

  const [handleSubmit] = useSubmitWithToast({
    func: async () =>
      cancelReservation({
        reservationId,
        propertyId,
        ownerId: accountId,
      }),
    successFunc: () => {
      setOpen(false);
      handleReservations();
    },
    successMessage: "The reservation is cancelled successfully.",
  });

  return (
    <>
      <span
        className="text-red-500 text-sm cursor-pointer"
        onClick={() => setOpen(true)}
      >
        Cancel Reservation
      </span>
      <Modal open={isOpen} setOpen={setOpen} size="lg">
        <ModalHeader onClose={() => setOpen(false)}>
          Cancel Reservation
        </ModalHeader>
        <div className="min-w-0 flex-1 px-2 md:grid md:grid-cols-2 md:gap-4 my-10">
          <div>
            <p className="text-sm font-medium text-indigo-600 truncate">
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                {ref}
              </span>
              {`${firstName} ${lastName}`}
            </p>
            <p className="mt-2 flex items-center text-sm text-gray-500">
              <UserGroupIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="truncate">
                {`${adults} adult${+adults > 1 ? "s" : ""}${
                  +children > 0 ? `, ${children} children` : ""
                }`}
              </span>
            </p>
          </div>
          <div className="mt-2 md:mt-0 block">
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
                <span className="truncate font-bold">
                  {displayDate(checkIn)} to {displayDate(checkOut)}
                </span>
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <MailIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="truncate">{email}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="rounded-md bg-red-50 p-4 mb-4 whitespace-pre-line">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm text-red-700">
                <p>
                  The reservation will be cancelled immediately and cannot be
                  reversed. Are you sure you want to continue?
                </p>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button onClick={handleSubmit} className="bg-red-500">
            Cancel Reservation
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReservationModal;
