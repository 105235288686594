import GuestPortalWrapper from "../guest";
import OwnerPortalWrapper, { OwnerPortalEntrance } from "../owner";
import StaffPortalWrapper, { StaffPortalEntrance } from "../staff";

const allRoutes = [
  {
    path: "/:propertyId/owner/",
    component: OwnerPortalEntrance,
  },
  {
    path: "/:propertyId/owner/:ownerId",
    component: OwnerPortalWrapper,
  },
  {
    path: "/:propertyId/owner/:ownerId/:tabId",
    component: OwnerPortalWrapper,
  },
  {
    path: "/:propertyId/reservation/:reservationId",
    component: GuestPortalWrapper,
  },
  {
    path: "/:propertyId/reservation/:reservationId/:tabId",
    component: GuestPortalWrapper,
  },
  {
    path: "/:propertyId/staff/",
    component: StaffPortalEntrance,
  },
  {
    path: "/:propertyId/staff/:staffId",
    component: StaffPortalWrapper,
  },
  {
    path: "/:propertyId/staff/:staffId/:tabId",
    component: StaffPortalWrapper,
  },
];

export default allRoutes;
