import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Page404 from "../pages/Page404";
import allRoutes from "./index";

const Layout = ({ children }: { children: any }) => {
  return <div>{children}</div>;
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        {allRoutes.map((route: { path: string; component: any }, index) => {
          const { path, component: Component } = route;
          return (
            <Route
              key={index}
              path={path}
              exact
              render={(props: any) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          );
        })}

        <Route render={() => <Page404 />} />
      </Switch>
    </Router>
  );
};

export default Routes;
