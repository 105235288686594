import { createContext, useEffect, useState } from "react";
import { getCodeCache } from "../action/actionAuth";
import { getReservationDetails } from "../action/actionGuest";
import { getOwnerDetails } from "../action/actionOwner";
import { getPropertyDetails } from "../action/actionProperty";
import { getStaffDetails } from "../action/actionStaff";

const init2: any = "imt";
export const PropertyContext = createContext(init2);

export function PropertyProvider({
  children,
  match,
}: {
  children: any;
  match: any;
}) {
  const propertyId = match.params.propertyId;
  const ownerId = match.params.ownerId;
  const reservationId = match.params.reservationId;
  const staffId = match.params.staffId;

  const init: any = {};
  const [property, setProperty] = useState(init);
  const [account, setAccount] = useState(init);
  const [reservation, setReservation] = useState(init);
  const [staff, setStaff] = useState(init);

  const [isLoggedIn, setLoggedIn]: [any, any] = useState(null);
  const [isLoading, setLoading]: [any, any] = useState(true);

  useEffect(() => {
    const handleProperty = async () => {
      const property2 = await getPropertyDetails(propertyId);

      setProperty(property2);
    };

    if (!!propertyId) {
      handleProperty();
    }
  }, [propertyId]);

  const handleOwner = async () => {
    try {
      const code = getCodeCache();
      if (!code) {
        throw Error("code error.");
      }
      const account2 = await getOwnerDetails({ propertyId, ownerId, code });
      setLoggedIn(true);
      setAccount(account2);

      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  const handleReservation = async () => {
    try {
      const code = getCodeCache(false);
      if (!code) {
        throw Error("code error.");
      }

      const reservation2 = await getReservationDetails({
        propertyId,
        reservationId,
        code,
      });

      setLoggedIn(true);
      setReservation(reservation2);

      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  const handleStaff = async () => {
    try {
      const code = getCodeCache();
      if (!code) {
        throw Error("code error.");
      }
      const staff2 = await getStaffDetails({ propertyId, staffId, code });
      setStaff(staff2);

      setLoggedIn(true);
      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ownerId) {
      handleOwner();
    }
    if (reservationId) {
      handleReservation();
    }
    if (staffId) {
      handleStaff();
    }
  }, [ownerId, reservationId, staffId]);

  return (
    <PropertyContext.Provider
      value={{
        propertyId,
        accountId: ownerId,
        staffId,
        staff,
        property,
        account,
        isLoggedIn,
        setLoggedIn,
        isLoading,
        setLoading,
        reservation,
        handleAccount: handleOwner,
        handleReservation,
        handleStaff,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
}
