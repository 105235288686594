import { Link } from "react-router-dom";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import { displayDate } from "../action";
import { useContext } from "react";
import { PropertyContext } from "../context/property";

export default function Reservation({
  propertyId,
  reservationId,
  reservation,
}: {
  propertyId: string;
  reservationId: string;
  reservation: any;
}) {
  const { property } = useContext(PropertyContext);
  const {
    firstName,
    lastName,
    mobile,
    email,
    phone,
    address,
    checkIn,
    checkOut,
    roomTypeName,
    isCheckedInOnline,
  } = reservation || {};

  const { allowOnlineCheckInPeriod } = property?.guestPortal || {};

  const today = dayjs().format("YYYY-MM-DD");
  const onlineCheckInAllowDate = dayjs(checkIn)
    .subtract(+allowOnlineCheckInPeriod, "days")
    .format("YYYY-MM-DD");
  const isOnlineCheckInAllowed =
    +allowOnlineCheckInPeriod > 0 ? onlineCheckInAllowDate <= today : true;

  const isOnlineCheckInReady = !isCheckedInOnline && !!isOnlineCheckInAllowed;

  const los = dayjs(checkOut).diff(checkIn, "days");

  return (
    <>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 sm:px-0">
            {isCheckedInOnline && (
              <div className="rounded-md bg-green-50 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">
                      You are checked in online already.
                    </h3>
                  </div>
                </div>
              </div>
            )}

            {isOnlineCheckInReady && (
              <div className="rounded-md bg-blue-50 p-4 mb-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      You can now check in online.
                    </p>
                    <p className="mt-3 text-sm md:mt-0 md:ml-6">
                      <Link
                        to={`/${propertyId}/reservation/${reservationId}/online-checkin`}
                        href="#"
                        className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                      >
                        Check In <span aria-hidden="true">&rarr;</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Personal details
                </h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {`${firstName} ${lastName}`}
                    </dd>
                  </div>
                  {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Mobile
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {mobile}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {email}
                    </dd>
                  </div> */}
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Check In
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {displayDate(checkIn)}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Check Out
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {displayDate(checkOut)}
                      <span className="text-gray-500 ml-2">{los} night(s)</span>
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Room Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {roomTypeName || "?"}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
