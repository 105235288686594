import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";

export default function Modal({
  open,
  setOpen,
  children,
  size = "md",
}: {
  open: boolean;
  setOpen: any;
  children?: any;
  size?: string;
}) {
  const sizeClassName: any = {
    md: "sm:max-w-lg sm:w-full sm:p-6",
    lg: "min-w-full w-full lg:min-w-min lg:max-w-7xl sm:p-6",
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${
                sizeClassName?.[size || "md"]
              }`}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export const ModalIcon = () => {
  return (
    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
      <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
    </div>
  );
};

export const ModalHeader = ({
  children,
  onClose,
}: {
  children?: any;
  onClose?: any;
}) => {
  return (
    <>
      <div className="text-center sm:text-left flex justify-between">
        <ModalTitle>{children}</ModalTitle>
        {onClose && (
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
      </div>
      <hr className="-mx-6 my-4" />
    </>
  );
};

export const ModalBody = ({ children }: { children?: any }) => {
  return <div>{children}</div>;
};

export const ModalTitle = ({ children }: { children?: any }) => {
  return (
    <Dialog.Title
      as="h3"
      className="text-lg leading-6 font-medium text-gray-900"
    >
      {children}
    </Dialog.Title>
  );
};

export const ModalFooter = ({ children }: { children?: any }) => {
  return (
    <div>
      <hr className="-mx-6 my-4" />
      <div className="text-center sm:text-left flex justify-between">
        {children}
      </div>
    </div>
  );
};
