import React, { useState, useContext } from "react";
import dayjs from "dayjs";
// import { ConfigContext } from "../../context/config.context";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from "@heroicons/react/outline";

function DatePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onCompleteSelectRange,
  isAlwaysOpen = false,
}) {
  const [calendarMonth, setCalendarMonth] = useState(
    dayjs(startDate || undefined).format("YYYY-MM")
  );
  const [hoverDate, setHoverDate] = useState(null);
  const today = dayjs().format("YYYY-MM-DD");
  const handleSelectDate = (date, isAvailable) => {
    if (date < today) {
      return;
    }
    if (!startDate && !endDate && isAvailable) {
      return setStartDate({ checkIn: date });
    }
    if (startDate && !endDate) {
      if (date <= startDate) {
        setStartDate({ checkIn: date });
        return setEndDate(null);
      }
      setEndDate({ checkOut: date });
      return onCompleteSelectRange();
    }
    if (startDate && endDate && isAvailable) {
      setStartDate({ checkIn: date });
      return setEndDate({ checkOut: null });
    }
  };

  const handleMoveMonth = (offset) => {
    const newCalendarMonth = dayjs(calendarMonth)
      .add(offset, "months")
      .format("YYYY-MM");

    setCalendarMonth(newCalendarMonth);
  };

  const calendarMonthYear = new Date(calendarMonth).getFullYear();
  const calendarMonthMonth = new Date(calendarMonth).getMonth() + 1;
  const secondCalendarMonth = dayjs(calendarMonth)
    .add(1, "months")
    .format("YYYY-MM");

  const secondCalendarMonthYear = new Date(secondCalendarMonth).getFullYear();
  const secondCalendarMonthMonth = new Date(secondCalendarMonth).getMonth() + 1;

  // <div className="bg-white p-8 border border-gray-200 rounded fixed inset-0 md:absolute md:inset-y-auto md:inset-x-auto md:mt-2 z-50"></div>

  return (
    <div className="fixed inset-0 md:inset-auto md:origin-top-left md:absolute md:left-0 md:mt-2 bg-white md:rounded-md md:shadow-2xl p-4 md:ring-1 md:ring-black md:ring-opacity-5 md:focus:outline-none">
      <div className="h-screen md:h-auto flex flex-col">
        <div className="border-b border-gray-200 mb-4 flex-between pb-4 md:hidden">
          <span>Select Dates</span>
          <div
            className="p-1 rounded-full text-gray-800 hover:bg-gray-100"
            onClick={onCompleteSelectRange}
          >
            <XIcon className="block h-4 w-4" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row flex-grow overflow-y-scroll md:flex-grow-0 md:overflow-auto">
          <MonthCalendar
            year={calendarMonthYear}
            month={calendarMonthMonth}
            startDate={startDate}
            endDate={endDate}
            onSelect={(date, isAvailable) =>
              handleSelectDate(date, isAvailable)
            }
            goBackward={true}
            onMoveMonth={handleMoveMonth}
            hoverDate={hoverDate}
            setHoverDate={setHoverDate}
            isAlwaysOpen={isAlwaysOpen}
          />
          <MonthCalendar
            year={secondCalendarMonthYear}
            month={secondCalendarMonthMonth}
            startDate={startDate}
            endDate={endDate}
            onSelect={(date, isAvailable) =>
              handleSelectDate(date, isAvailable)
            }
            goForward={true}
            onMoveMonth={handleMoveMonth}
            hoverDate={hoverDate}
            setHoverDate={setHoverDate}
            isAlwaysOpen={isAlwaysOpen}
          />
        </div>
      </div>
      <div style={{ width: "580px" }} className="hidden md:block"></div>
    </div>
  );
}

const MonthCalendar = ({
  year,
  month,
  startDate,
  endDate,
  onSelect,
  goBackward = false,
  goForward = false,
  onMoveMonth,
  hoverDate,
  setHoverDate,
  isAlwaysOpen,
}) => {
  // const { config, allotments, filter } = useContext(ConfigContext);
  const today = dayjs().format("YYYY-MM-DD");
  const firstDay = new Date(year, Number(month) - 1).getDay();
  const daysInMonth = 32 - new Date(year, Number(month) - 1, 32).getDate();
  const isCurrentMonth =
    dayjs().format("YYYY-MM") ===
    dayjs(new Date(year, Number(month) - 1)).format("YYYY-MM");

  const displayMonth = dayjs(new Date(year, Number(month) - 1)).format(
    "MMMM YYYY"
  );

  // const { mode, roomTypeCode } = filter || {};
  // const filterRoomTypeId = Object.keys(config?.roomTypes || {}).find(
  //   (roomTypeId) => config?.roomTypes?.[roomTypeId]?.code === roomTypeCode
  // );

  const monthObj = [];

  let date = 1;
  for (let i = 0; i < 6; i++) {
    monthObj[i] = [];

    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < firstDay) {
        monthObj[i][j] = "";
      } else if (date > daysInMonth) {
        break;
      } else {
        monthObj[i][j] = date;
        date++;
      }
    }
  }

  return (
    <div className="mr-0 mb-4 md:mb-0 md:mr-4">
      <div
        className={`text-center mb-4 flex justify-between items-center text-gray-900 mx-auto`}
        style={{ maxWidth: "280px" }}
      >
        <div className="w-6">
          <span
            role="button"
            onClick={() => onMoveMonth(-1)}
            className="select-none"
          >
            {goBackward && !isCurrentMonth ? (
              <div className="p-1 rounded-full text-gray-800 hover:bg-gray-100">
                <ChevronLeftIcon className="block h-4 w-4" />
              </div>
            ) : null}
          </span>
        </div>
        <span className="text-sm">{displayMonth}</span>
        <div className="w-6">
          <span
            role="button"
            onClick={() => onMoveMonth(1)}
            className="select-none"
          >
            {goForward ? (
              <div className="p-1 rounded-full text-gray-800 hover:bg-gray-100">
                <ChevronRightIcon className="block h-4 w-4 " />
              </div>
            ) : null}
          </span>
        </div>
      </div>

      <div className="flex justify-center">
        <table className="text-center text-gray-800">
          <thead className="text-xs">
            <tr>
              <th className="text-red-500">Sun</th>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
              <th className="text-red-500">Sat</th>
            </tr>
          </thead>
          <tbody>
            {monthObj.map((line, i) => (
              <tr key={`month-${i}`}>
                {line.map((day, index) => {
                  const date = `${year}-${String(month).padStart(
                    2,
                    "0"
                  )}-${String(day).padStart(2, "0")}`;
                  const isStartEnd = date === startDate || date === endDate;
                  const isBetweenStartEnd = date > startDate && date < endDate;
                  const isViaStart =
                    !!startDate &&
                    !endDate &&
                    !!hoverDate &&
                    date > startDate &&
                    date <= hoverDate;
                  const isPast = date < today;
                  if (!day) {
                    return <td key={`${date}-${index}`}></td>;
                  }

                  // const available = Object.keys(
                  //   allotments?.[date] || {}
                  // ).reduce((sum, roomTypeId) => {
                  //   const av = allotments?.[date]?.[roomTypeId] || 0;
                  //   if (mode === "only") {
                  //     if (filterRoomTypeId === roomTypeId) {
                  //       sum += av;
                  //     }
                  //   } else {
                  //     sum += av;
                  //   }
                  //   return sum;
                  // }, 0);

                  // const isAvailable = isAlwaysOpen
                  //   ? true
                  //   : available !== 0 && !isPast;
                  const isAvailable = true;

                  return (
                    <td
                      key={date}
                      role={!isPast ? "button" : ""}
                      className={`cursor-pointer border border-gray-100 text-xs ${
                        isStartEnd
                          ? `bg-indigo-500 hover:bg-indigo-500 text-white`
                          : ""
                      } ${
                        isBetweenStartEnd || isViaStart
                          ? `bg-indigo-200 text-white hover:bg-indigo-300`
                          : ""
                      } ${
                        isPast
                          ? "text-gray-300 bg-gray-100 cursor-not-allowed"
                          : ""
                      } ${
                        !isAvailable && !isPast
                          ? "line-through text-red-300 bg-red-50 cursor-not-allowed"
                          : ""
                      } hover:bg-gray-200`}
                      style={{ width: "40px", height: "40px" }}
                      onClick={() => {
                        onSelect(date, isAvailable);
                      }}
                      onMouseEnter={() => {
                        setHoverDate(date);
                      }}
                      onMouseLeave={() => {
                        setHoverDate(null);
                      }}
                    >
                      {day}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DatePicker;
