import axios from "axios";
import dayjs from "dayjs";
import { uploadFile } from "./actionAuth";

const uri = process.env.REACT_APP_PORTAL_URI;
const apiUsername = process.env.REACT_APP_API_USERNAME || "";
const apiPassword = process.env.REACT_APP_API_PASSWORD || "";

const auth = {
  username: apiUsername,
  password: apiPassword,
};

//HOUSEKEEPING
export const getOverdueHousekeepingTasks = async ({
  propertyId,
  staffId,
  code,
}: {
  propertyId: string;
  staffId: string;
  code: string;
}) => {
  const today = dayjs().format("YYYY-MM-DD");
  const url = `${uri}/staff/housekeeping`;
  const params = {
    propertyId,
    staffId,
    code,
    endDate: today,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const getHousekeepingTasks = async ({
  propertyId,
  staffId,
  code,
  startDate,
  endDate,
}: {
  propertyId: string;
  staffId: string;
  code: string;
  startDate: string;
  endDate: string;
}) => {
  const url = `${uri}/staff/housekeeping`;
  const params = {
    propertyId,
    staffId,
    code,
    startDate,
    endDate,
    mode: "same",
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const getHousekeepingTaskDetails = async ({
  propertyId,
  housekeepingId,
  staffId,
}: {
  propertyId: string;
  housekeepingId: string;
  staffId: string;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/housekeeping/${housekeepingId}`;
  const params = {
    propertyId,
    housekeepingId,
    code,
    staffId,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const startHousekeepingTask = async ({
  propertyId,
  housekeepingId,
  staffId,
  roomId,
  guide,
}: {
  propertyId: string;
  housekeepingId: string;
  staffId: string;
  roomId: string;
  guide: any;
}) => {
  // we only keep record of name and description
  // remove unwanted fields
  const updatedGuide = Object.fromEntries(
    Object.entries(guide)
      .filter(([id, guide]: any) => {
        return !!guide?.name;
      })
      .map(([id, guide]: any) => {
        const { name, description, order } = guide;
        return [id, { name, description, order }];
      })
  );

  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/housekeeping/${housekeepingId}`;
  const params = {
    propertyId,
    housekeepingId,
    code,
    staffId,
  };

  const body = {
    action: "START_TASK",
    roomId,
    guide: updatedGuide,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const updateHousekeepingTask = async ({
  propertyId,
  staffId,
  housekeepingId,
  fieldName,
  fieldValue,
}: {
  propertyId: string;
  staffId: string;
  housekeepingId: string;
  fieldName: string;
  fieldValue: any;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/housekeeping/${housekeepingId}`;
  const params = {
    propertyId,
    code,
    staffId,
  };

  const body = {
    action: "UPDATE_TASK",
    fieldName,
    fieldValue,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const uploadHousekeepingImages = async ({
  files,
  propertyId,
}: {
  files: any;
  propertyId: string;
}) => {
  const result = await Promise.all(
    Array.from(files || []).map(async (file: any) => {
      const result: any = await uploadFile({
        hotelId: propertyId,
        file,
        folder: "housekeeping",
      });
      result.isIdFile = true;
      return result;
    })
  );

  return result;
};

export const completeHousekeepingTask = async ({
  propertyId,
  housekeepingId,
  staffId,
  files,
  comments,
  staff,
  task,
}: {
  propertyId: string;
  housekeepingId: string;
  staffId: string;
  files: any;
  comments: string;
  staff: any;
  task: any;
}) => {
  const code = localStorage.getItem("code") || "";

  const url = `${uri}/staff/housekeeping/${housekeepingId}`;
  const params = {
    propertyId,
    housekeepingId,
    code,
    staffId,
  };

  const body = {
    action: "COMPLETE_TASK",
    staff,
    comments,
    task,
    attachments: files,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

// INSPECTION
export const getInspectionTasks = async ({
  propertyId,
  startDate,
  endDate,
  staffId,
  code,
}: {
  propertyId: string;
  startDate: string;
  endDate: string;
  staffId: string;
  code: string;
}) => {
  const url = `${uri}/staff/inspection`;
  const params = {
    propertyId,
    startDate,
    endDate,
    staffId,
    code,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const getInspectionTaskDetails = async ({
  propertyId,
  roomId,
  staffId,
  reservationId,
}: {
  propertyId: string;
  roomId: string;
  staffId: string;
  reservationId: string;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/inspection/${roomId}`;
  const params = {
    propertyId,
    code,
    staffId,
    reservationId,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const startInspection = async ({
  propertyId,
  reservationId,
  staffId,
  roomId,
  guide,
}: {
  propertyId: string;
  reservationId: string;
  staffId: string;
  roomId: string;
  guide: any;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/inspection/${roomId}`;
  const params = {
    propertyId,
    code,
    staffId,
  };

  // we only keep record of name and description
  // remove unwanted fields
  const updatedGuide = Object.fromEntries(
    Object.entries(guide)
      .filter(([id, guide]: any) => {
        return !!guide?.name;
      })
      .map(([id, guide]: any) => {
        const { name, description } = guide;
        return [id, { name, description }];
      })
  );

  const body = {
    action: "START_INSPECTION",
    reservationId,
    guide: updatedGuide,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

// return the url list of the images uploaded
export const uploadInspectionImages = async ({
  files,
  propertyId,
}: {
  files: any;
  propertyId: string;
}) => {
  const result = await Promise.all(
    Array.from(files || []).map(async (file: any) => {
      const result: any = await uploadFile({
        hotelId: propertyId,
        file,
        folder: "inspection-reports",
      });

      const { path } = result;

      return path;
    })
  );

  return result;
};

export const updateInspectionReport = async ({
  propertyId,
  reservationId,
  staffId,
  roomId,
  guideId,
  guide,
  result,
  images,
}: {
  propertyId: string;
  reservationId: string;
  staffId: string;
  roomId: string;
  guideId: string;
  guide: any;
  result: any;
  images?: any;
}) => {
  const { name, description, order } = guide?.[guideId] || {};

  const imagesUrlList = !!images
    ? await uploadInspectionImages({
        files: images,
        propertyId,
      })
    : [];

  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/inspection/${roomId}`;
  const params = {
    propertyId,
    code,
    staffId,
  };

  // do not add images value if no image field is changed
  const resultUpdated = {
    [guideId]: !!images
      ? { images: imagesUrlList, ...result, name, description, order }
      : { ...result, name, description, order },
  };

  const body = {
    action: "UPDATE_REPORT",
    reservationId,
    result: resultUpdated,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const completeInspection = async ({
  propertyId,
  reservationId,
  staffId,
  staff,
  roomId,
  results,
  markRoomReady,
}: {
  propertyId: string;
  reservationId: string;
  staffId: string;
  staff: any;
  roomId: string;
  results: any;
  markRoomReady: boolean;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/inspection/${roomId}`;
  const params = {
    propertyId,
    code,
    staffId,
  };

  const body = {
    action: "COMPLETE_INSPECTION",
    reservationId,
    staff,
    results,
    markRoomReady,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

// TASKS
export const getTodoTasks = async ({
  propertyId,
  startDate,
  endDate,
  staffId,
  code,
}: {
  propertyId: string;
  startDate: string;
  endDate: string;
  staffId: string;
  code: string;
}) => {
  const url = `${uri}/staff/task`;
  const params = {
    propertyId,
    startDate,
    endDate,
    staffId,
    code,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const getTodoTaskDetails = async ({
  propertyId,
  todoId,
  staffId,
}: {
  propertyId: string;
  todoId: string;
  staffId: string;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/task/${todoId}`;
  const params = {
    propertyId,
    code,
    staffId,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const startTodoTask = async ({
  propertyId,
  todoId,
  staffId,
  roomId,
}: {
  propertyId: string;
  todoId: string;
  staffId: string;
  roomId?: string;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/task/${todoId}`;
  const params = !!roomId
    ? {
        propertyId,
        code,
        staffId,
        roomId,
      }
    : {
        propertyId,
        code,
        staffId,
      };

  const body = {
    action: "START_TASK",
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const updateTodoTask = async ({
  propertyId,
  staffId,
  todoId,
  fieldName,
  fieldValue,
}: {
  propertyId: string;
  staffId: string;
  todoId: string;
  fieldName: string;
  fieldValue: any;
}) => {
  const code = localStorage.getItem("code") || "";
  const url = `${uri}/staff/task/${todoId}`;
  const params = {
    propertyId,
    code,
    staffId,
  };

  const body = {
    action: "UPDATE_TASK",
    fieldName,
    fieldValue,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const uploadTodoImages = async ({
  files,
  propertyId,
}: {
  files: any;
  propertyId: string;
}) => {
  return await Promise.all(
    Array.from(files || []).map(async (file: any) => {
      const result: any = await uploadFile({
        hotelId: propertyId,
        file,
        folder: "todos",
      });
      return result;
    })
  );
};

export const completeTodoTask = async ({
  propertyId,
  todoId,
  staffId,
  files,
  comments,
  staff,
  task,
}: {
  propertyId: string;
  todoId: string;
  staffId: string;
  files: any;
  comments: string;
  staff: any;
  task: any;
}) => {
  const code = localStorage.getItem("code") || "";

  const url = `${uri}/staff/task/${todoId}`;
  const params = {
    propertyId,
    todoId,
    code,
    staffId,
  };

  const body = {
    action: "COMPLETE_TASK",
    staff,
    comments,
    task,
    attachments: files,
  };

  try {
    const result = await axios.post(url, body, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

// GENERAL

export const getStaffDetails = async ({
  propertyId,
  staffId,
  code,
}: {
  propertyId: string;
  staffId: string;
  code: string;
}) => {
  const url = `${uri}/staff/`;
  const params = {
    propertyId,
    staffId,
    code,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const getStaffVerificationMethods = async ({
  propertyId,
  staffId,
}: {
  propertyId: string;
  staffId: string;
}) => {
  const url = `${uri}/staff/code`;
  const params = {
    propertyId,
    staffId,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error) {}
};

export const sendCode = async ({
  propertyId,
  staffId,
  method,
}: {
  propertyId: string;
  staffId: string;
  method: string;
}) => {
  const url = `${uri}/staff/send-code`;
  const params = {
    propertyId,
    staffId,
    method,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const verifyCode = async ({
  propertyId,
  staffId,
  verificationCode,
}: {
  propertyId: string;
  staffId: string;
  verificationCode: string;
}) => {
  const url = `${uri}/staff/verify-code`;
  const params = {
    propertyId,
    staffId,
    verificationCode,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const findStaffId = async ({
  propertyId,
  search,
}: {
  propertyId: string;
  search: string;
}) => {
  const url = `${uri}/staff/find-staff-id`;
  const params = {
    propertyId,
    search,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    throw Error(error?.response?.data?.message || error.message);
  }
};
