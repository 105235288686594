import { useContext } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { PropertyContext, PropertyProvider } from "../context/property";
import Login from "./Login";
import Loading from "../components/Loading";
import Reservation from "./Reservation";
import OnlineCheckIn from "./OnlineCheckIn";

function GuestPortalWrapper({ match }: { match: any }) {
  const propertyId = match.params.propertyId;
  const reservationId = match.params.reservationId;
  const tabId = match.params.tabId;

  return (
    <PropertyProvider match={match}>
      <GuestPortal
        tabId={tabId}
        propertyId={propertyId}
        reservationId={reservationId}
      />
    </PropertyProvider>
  );
}

const GuestPortal = ({
  tabId,
  propertyId,
  reservationId,
}: {
  tabId: string;
  propertyId: string;
  reservationId: string;
}) => {
  const { isLoading, isLoggedIn } = useContext(PropertyContext);

  return (
    <>
      {isLoggedIn === null ? (
        <Loading loadingText={isLoading} />
      ) : isLoggedIn === true ? (
        <div className="min-h-screen bg-white">
          <Guest
            tabId={tabId}
            propertyId={propertyId}
            reservationId={reservationId}
          />
        </div>
      ) : isLoggedIn === false ? (
        <Login propertyId={propertyId} reservationId={reservationId} />
      ) : (
        <Loading loadingText={isLoading} />
      )}
    </>
  );
};

const Guest = ({
  tabId,
  propertyId,
  reservationId,
}: {
  tabId: string;
  propertyId: string;
  reservationId: string;
}) => {
  const { property, reservation, isLoading } = useContext(PropertyContext);

  const { name: propertyName } = property || {};

  const { lastName } = reservation || {};

  const navigation = [
    { name: "Profile", href: "profile", current: false },
    { name: "Online Check In", href: "online-checkin", current: false },
  ];

  return (
    <>
      <Helmet>
        <title>{`Guest Portal - ${propertyName}`}</title>
      </Helmet>

      {isLoading && <Loading loadingText={isLoading} />}

      <Header
        tabId={tabId}
        url={`/${propertyId}/reservation/${reservationId}/`}
        navigation={navigation}
        reservation={reservation}
        property={property}
      />
      <div className="py-10 min-h-screen bg-gray-100">
        {tabId === "profile" ? (
          <Reservation
            propertyId={propertyId}
            reservationId={reservationId}
            reservation={reservation}
          />
        ) : tabId === "online-checkin" ? (
          <OnlineCheckIn
            propertyId={propertyId}
            reservationId={reservationId}
            reservation={reservation}
          />
        ) : (
          <Reservation
            propertyId={propertyId}
            reservationId={reservationId}
            reservation={reservation}
          />
        )}
      </div>

      <Footer property={property} />
    </>
  );
};

export default GuestPortalWrapper;
