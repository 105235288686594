import React, { useContext, useEffect, useState } from "react";
import { PropertyContext } from "../context/property";
import dayjs from "dayjs";
import {
  completeTodoTask,
  getTodoTaskDetails,
  getTodoTasks,
  startTodoTask,
  updateTodoTask,
  uploadTodoImages,
} from "../action/actionStaff";
import { LoadingContent } from "../components/Loading";
import { displayDate } from "../action";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import FileUpload from "../components/FileUpload";
import { Button } from "../components";
import useSubmitWithToast from "../action/useSubmitWithToast";
import Badge from "../components/Badge";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
const Task = ({
  propertyId,
  staffId,
}: {
  propertyId: string;
  staffId: string;
}) => {
  const [selectedTodoId, setSelectedTodoId] = useState("");
  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            Task
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-2">
              BETA
            </span>
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-8 sm:px-0">
            {selectedTodoId ? (
              <TaskDetailPage
                todoId={selectedTodoId}
                propertyId={propertyId}
                staffId={staffId}
                setSelectedTodoId={setSelectedTodoId}
              />
            ) : (
              <TaskList
                propertyId={propertyId}
                staffId={staffId}
                setSelectedTodoId={setSelectedTodoId}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
};

const TaskList = ({
  propertyId,
  staffId,
  setSelectedTodoId,
}: {
  propertyId: string;
  staffId: string;
  setSelectedTodoId: any;
}) => {
  const [isLoading, setLoading] = useState(true);
  const { setLoggedIn } = useContext(PropertyContext);
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState("today");

  const tabs = [
    { value: "today", name: "Today" },
    { value: "tomorrow", name: "Tomorrow" },
    { value: "next7days", name: "Next 7 days" },
    { value: "next30days", name: "Next 30 days" },
    { value: "next90days", name: "Next 90 days" },
  ];

  const handleTasks = async () => {
    const code = localStorage.getItem("code") || "";
    setTasks([]);
    setLoading(true);

    const today = dayjs().format("YYYY-MM-DD");
    const tomorrow = dayjs().add(1, "days").format("YYYY-MM-DD");

    try {
      if (activeTab === "today") {
        const tasks2 = await getTodoTasks({
          propertyId,
          startDate: today,
          endDate: today,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "tomorrow") {
        const tasks2 = await getTodoTasks({
          propertyId,
          startDate: tomorrow,
          endDate: tomorrow,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next7days") {
        const startDate = tomorrow;
        const endDate = dayjs().add(7, "days").format("YYYY-MM-DD");
        const tasks2 = await getTodoTasks({
          propertyId,
          startDate: startDate,
          endDate: endDate,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next30days") {
        const startDate = tomorrow;
        const endDate = dayjs().add(30, "days").format("YYYY-MM-DD");
        const tasks2 = await getTodoTasks({
          propertyId,
          startDate: startDate,
          endDate: endDate,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next90days") {
        const startDate = tomorrow;
        const endDate = dayjs().add(90, "days").format("YYYY-MM-DD");
        const tasks2 = await getTodoTasks({
          propertyId,
          startDate: startDate,
          endDate: endDate,
          staffId,
          code,
        });
        setTasks(tasks2);
      }
      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTasks();
  }, [activeTab]);

  return (
    <>
      {/* Tabs */}
      <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
        <div className="mb-4 sm:mb-0">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.value} value={tab.value}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <span
                  key={tab.value}
                  onClick={() => setActiveTab(tab.value)}
                  className={classNames(
                    tab.value === activeTab
                      ? "bg-indigo-100 text-indigo-700"
                      : "text-gray-500 hover:text-gray-700",
                    "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={tab.value === activeTab ? "page" : undefined}
                >
                  {tab.name}
                </span>
              ))}
            </nav>
          </div>
        </div>

        <div>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
            {tasks?.length} tasks
          </span>
        </div>
      </div>

      {isLoading ? (
        <LoadingContent />
      ) : (
        <TaskTable
          tasks={tasks}
          setSelectedTodoId={setSelectedTodoId}
          isOverdue={activeTab !== "today" && activeTab !== "tomorrow"}
          staffId={staffId}
        />
      )}
    </>
  );
};

const TaskTable = ({
  tasks,
  setSelectedTodoId,
  isOverdue,
  staffId,
}: {
  tasks: any;
  setSelectedTodoId: any;
  isOverdue: boolean;
  staffId: string;
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          {isOverdue && (
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Date
            </th>
          )}
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Task Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Supervisor
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {tasks
          .sort((a: any, b: any) =>
            a.date === b.date
              ? a.roomName > b.roomName
                ? 1
                : -1
              : a.date > b.date
              ? 1
              : -1
          )
          .map((task: any) => (
            <TaskTableRow
              task={task}
              key={task.id}
              setSelectedTodoId={setSelectedTodoId}
              isOverdue={isOverdue}
              staffId={staffId}
            />
          ))}
      </tbody>
    </table>
  );
};

const TaskTableRow = ({
  task,
  setSelectedTodoId,
  isOverdue,
  staffId,
}: {
  task: any;
  setSelectedTodoId: any;
  isOverdue: boolean;
  staffId: string;
}) => {
  const {
    id,
    ref,
    date,
    description: taskName,
    workflowStatus,
    type,
    assignToName,
    assignTo,
    supervisorName,
    supervisor,
    isStarted,
    isEnded,
  } = task;

  const isInProgress = !!isStarted && !isEnded;

  return (
    <tr key={id}>
      {isOverdue && (
        <td className="px-3 py-4 text-sm text-gray-700">{displayDate(date)}</td>
      )}

      <td className="px-3 py-4 text-sm text-gray-700">
        {!!ref && <Badge>{ref}</Badge>}
        {taskName}

        {!!workflowStatus && (
          <Badge color="blue" className="ml-2">
            {workflowStatus}
          </Badge>
        )}

        {!!type && (
          <Badge color="purple" className="ml-2">
            {type}
          </Badge>
        )}

        {isInProgress && (
          <Badge color="green" className="ml-2">
            <svg
              className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx={4} cy={4} r={3} />
            </svg>
            In Progress
          </Badge>
        )}
      </td>

      <td className="px-3 py-4 text-sm">
        {supervisor === staffId ? (
          <>
            <Badge>Supervising</Badge>
            <span className={!!assignTo ? "text-gray-700" : "text-gray-400"}>
              {!!assignTo ? `Assigned To ${assignToName}` : `Unassigned`}
            </span>
          </>
        ) : (
          <span className={!!supervisor ? "text-gray-700" : "text-gray-400"}>
            {supervisorName}
          </span>
        )}
      </td>

      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <a
          href="#"
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => {
            setSelectedTodoId(id);
          }}
        >
          Details
        </a>
      </td>
    </tr>
  );
};

const TaskDetailPage = ({
  todoId,
  propertyId,
  staffId,
  setSelectedTodoId,
}: {
  todoId: string;
  propertyId: string;
  staffId: string;
  setSelectedTodoId: any;
}) => {
  const [isLoading, setLoading] = useState(true);
  const { staff, setLoggedIn } = useContext(PropertyContext);
  const [task, setTask]: [any, any] = useState(null);
  const [summaryImages, setSummaryImages] = useState<any[]>([]);
  const [comments, setComments] = useState("");
  const [isReady, setIsReady] = useState(true);

  const summaryImageUrls = summaryImages.map((image: any) => image?.url);

  const {
    type,
    isStarted,
    isEnded,
    roomName,
    roomId,
    roomTypeName,
    attachments: prevAttachments = [],
    assignTo,
    description: name,
    comments: description,
    tenancyId,
    reservationId,
    currentReservation,
    accountId,
    workflowStatus,
    assignToName,
    supervisor,
  } = task || {};

  const {
    id: currentResId,
    firstName: firstNameCurrent,
    lastName: lastNameCurrent,
    checkIn: checkInCurrent,
    checkOut: checkOutCurrent,
    adults: adultsCurrent,
    children: childrenCurrent = 0,
    ref: refCurrent,
    departureTime,
    lockOffBedrooms: lockOffBedroomsCurrent,
    isCheckedOut: isCheckedOutCurrent,
  } = currentReservation || {};

  const losCurrent = dayjs(checkOutCurrent).diff(checkInCurrent, "days");

  const isInProgress = !!isStarted && !isEnded;

  const isTaskDue = true;
  // supervisor can only view  the task but not start the task
  const canStaffStartTask = assignTo === staffId;

  const handleTodoTask = async () => {
    try {
      setLoading(true);
      const task2 = await getTodoTaskDetails({
        propertyId,
        todoId,
        staffId,
      });

      setTask(task2);
      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  const handleUploadSummaryImages = async (files: any) => {
    const attachments = await uploadTodoImages({ files, propertyId });
    const newAttachments = [...summaryImages, ...attachments];
    setSummaryImages(newAttachments);

    await updateTodoTask({
      propertyId,
      staffId,
      todoId,
      fieldName: "attachments",
      fieldValue: newAttachments,
    });
  };

  const handleDeleteSummaryImage = async (url: any) => {
    const newAttachments = summaryImages.filter(
      (attachment: any) => attachment?.url !== url
    );

    await updateTodoTask({
      propertyId,
      staffId,
      todoId,
      fieldName: "attachments",
      fieldValue: newAttachments,
    });

    setSummaryImages(newAttachments);
  };

  const [handleStartTask] = useSubmitWithToast({
    func: () => {
      return startTodoTask({
        propertyId,
        todoId,
        staffId,
      });
    },
    successMessage: "The task starts now.",
    successFunc: () => {
      handleTodoTask();
    },
  });

  const [handleCompleteTask] = useSubmitWithToast({
    func: async () => {
      try {
        await completeTodoTask({
          propertyId,
          todoId,
          staffId,
          files: summaryImages,
          comments,
          staff,
          task,
        });
      } catch (err: any) {
        alert(err?.message);
        throw Error(err?.message);
      }

      return Promise.resolve();
    },
    successMessage: "The task is successfully completed.",
    successFunc: () => {
      handleTodoTask();
      setSelectedTodoId("");
    },
    loadingText: "Submitting your task record...",
  });

  useEffect(() => {
    handleTodoTask();
  }, [todoId]);

  useEffect(() => {
    setSummaryImages(prevAttachments);
  }, [task]);

  return (
    <div className="">
      {isLoading ? (
        <LoadingContent />
      ) : (
        <>
          <button
            type="button"
            className="mb-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setSelectedTodoId("")}
          >
            <ChevronLeftIcon className="h-4 w-4 mr-1" /> Go back
          </button>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div
              className={`px-4 py-5 sm:px-6 ${
                isInProgress ? "text-white bg-green-500" : "text-auto bg-white"
              }`}
            >
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div>
                  <h3
                    className={`text-lg leading-6 font-medium ${
                      !isInProgress ? "text-gray-700" : "text-white"
                    }`}
                  >
                    <span className="mr-2">{name}</span>
                    {!!workflowStatus && (
                      <Badge color="blue">{workflowStatus}</Badge>
                    )}
                    {!!type && (
                      <Badge className="justify-self-start">{type}</Badge>
                    )}
                  </h3>
                  {!!roomId && (
                    <p
                      className={`mt-1 max-w-2xl flex flex-col ${
                        !isInProgress ? "text-gray-500" : "text-white"
                      }`}
                    >
                      <span className="">{roomName}</span>
                      <span className="text-sm">{roomTypeName}</span>
                    </p>
                  )}
                </div>

                <div>
                  {supervisor === staffId && (
                    <div
                      className={`${isInProgress ? "text-white " : ""} mb-1`}
                    >
                      <Badge>Supervising</Badge>
                      <span
                        className={
                          !!assignTo ? "text-gray-700" : "text-gray-400"
                        }
                      >
                        {!!assignTo
                          ? `Assigned To ${assignToName}`
                          : `Unassigned`}
                      </span>
                    </div>
                  )}
                  <dd
                    className={`${
                      isInProgress ? "text-white " : "text-indigo-600"
                    } text-xl sm:text-right mb-1`}
                  >
                    <Countdown task={task} />
                  </dd>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                {!!reservationId && (
                  <div className="py-4 px-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 mb-4">
                      <span>
                        <span>Current Reservation</span>
                      </span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div>
                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                          {refCurrent}
                        </span>
                        <span>{`${lastNameCurrent}, ${firstNameCurrent}`}</span>
                        <span className="text-gray-500">{` (${adultsCurrent}A${
                          childrenCurrent || 0
                        }C)`}</span>
                        {lockOffBedroomsCurrent > 0 && (
                          <span className="ml-2 text-indigo-500">
                            {lockOffBedroomsCurrent}B
                          </span>
                        )}
                      </div>
                      <div className="mt-2">
                        <span>
                          {type === "depart-clean" && (
                            <>
                              {isCheckedOutCurrent ? (
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 mr-1">
                                  Checked Out
                                </span>
                              ) : !!departureTime ? (
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800 mr-1">
                                  Departs between {departureTime}
                                </span>
                              ) : null}
                            </>
                          )}

                          {`${displayDate(checkInCurrent)} to ${displayDate(
                            checkOutCurrent
                          )}`}
                        </span>
                        <span className="text-gray-500">{` (${losCurrent} nights)`}</span>
                      </div>
                    </dd>
                  </div>
                )}

                {!!description && (
                  <div className="py-4 px-5 sm:py-5 ">
                    <span className="text-sm font-medium text-gray-500 mb-2 whitespace-pre-line">
                      {description}
                    </span>
                  </div>
                )}

                <div className="border-t border-gray-200 p-0">
                  <dl className="divide-y divide-gray-200">
                    {isTaskDue && canStaffStartTask && (
                      <>
                        {isInProgress ? (
                          <>
                            <div className="flex flex-col px-8 justify-center items-center sm:items-start">
                              <div className="mt-4 w-full">
                                <label className="mb-2 block text-center sm:text-left">
                                  General Comments
                                </label>
                                <textarea
                                  rows={4}
                                  name="comment"
                                  id="comment"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  placeholder="Enter your inspection comments"
                                  value={comments}
                                  onChange={(e) => setComments(e.target.value)}
                                />
                              </div>
                              <div className="my-6 w-full">
                                <label className="mb-2 block text-center sm:text-left">
                                  Images
                                </label>

                                <FileUpload
                                  uploadFunc={handleUploadSummaryImages}
                                  accept="image/*"
                                  fileUrls={summaryImageUrls}
                                  handleDeleteFunc={handleDeleteSummaryImage}
                                  setIsReady={setIsReady}
                                  label="Upload Images"
                                />
                              </div>
                            </div>

                            <div className="py-4 flex-col text-center">
                              <Button
                                onClick={handleCompleteTask}
                                className="mx-auto"
                                disabled={!isReady}
                              >
                                Complete
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="py-4 grid place-content-center">
                            <Button onClick={handleStartTask}>
                              Start Task
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </dl>
                </div>
              </dl>
            </div>
          </div>
          <div className="text-xs text-gray-500 mt-6 flex flex-col md:flex-row">
            <span className="mr-4">Task ID: {todoId}</span>
            {!!currentResId && (
              <>
                <span className="mr-4">Current Res ID: {currentResId}</span>
              </>
            )}
            {!!roomId && <span className="mr-4">Room ID: {roomId}</span>}
            {!!tenancyId && (
              <span className="mr-4">Tenancy ID: {tenancyId}</span>
            )}
            {!!accountId && (
              <span className="mr-4">Account ID: {accountId}</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Task;
export const Countdown = ({ task }: { task: any }) => {
  const [time, setTime] = useState(0);
  const { isStarted, isEnded, timestampStarted } = task || {};

  const isInProgress = !!isStarted && !isEnded;

  useEffect(() => {
    if (timestampStarted?._seconds && isInProgress) {
      const duration = -dayjs
        .unix(timestampStarted?._seconds)
        .diff(new Date(), "seconds");
      if (duration >= 0) {
        const tick = () => setTime(+(duration || 0) + 1);
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
      }
    }
  }, [timestampStarted?._seconds, time]);

  const minutes = Math.floor(time / 60);
  // const seconds = time - minutes * 60;

  if (!isInProgress) {
    return null;
  }

  return <span>{minutes} mins</span>;
};
