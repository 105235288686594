import React from "react";

const Badge = ({
  className = "",
  color = "indigo",
  children,
}: {
  className?: string;
  color?: string;
  children: React.ReactNode;
}) => {
  return (
    <span
      className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-${color}-100 text-${color}-800 mr-2 ${className}`}
    >
      {children}
    </span>
  );
};

export default Badge;
