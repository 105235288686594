import axios from "axios";
import { getCodeCache, uploadFile } from "./actionAuth";

const uri = process.env.REACT_APP_PORTAL_URI;

const apiUsername = process.env.REACT_APP_API_USERNAME || "";
const apiPassword = process.env.REACT_APP_API_PASSWORD || "";

const auth = {
  username: apiUsername,
  password: apiPassword,
};

export const getReservationVerificationMethods = async ({
  propertyId,
  reservationId,
}: {
  propertyId: string;
  reservationId: string;
}) => {
  const url = `${uri}/reservation/code`;
  const params = {
    propertyId,
    reservationId,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    throw Error(error?.response?.data?.message || error?.message);
  }
};

export const sendCode = async ({
  propertyId,
  reservationId,
  method,
}: {
  propertyId: string;
  reservationId: string;
  method: string;
}) => {
  const url = `${uri}/reservation/send-code`;
  const params = {
    propertyId,
    reservationId,
    method,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const verifyCode = async ({
  propertyId,
  reservationId,
  verificationCode,
}: {
  propertyId: string;
  reservationId: string;
  verificationCode: string;
}) => {
  const url = `${uri}/reservation/verify-code`;
  const params = {
    propertyId,
    reservationId,
    verificationCode,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const getReservationDetails = async ({
  propertyId,
  reservationId,
  code,
}: {
  propertyId: string;
  reservationId: string;
  code: string;
}) => {
  const url = `${uri}/reservation/`;
  const params = {
    propertyId,
    reservationId,
    code,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const submitOnlineCheckIn = async ({
  propertyId,
  reservationId,
  profile,
  code,
  attachments,
}: {
  propertyId: string;
  reservationId: string;
  profile: any;
  code: string;
  attachments?: any[];
}) => {
  // https://us-central1-resly-pms-production-cloudfunctions-net.relay.evervault.com/portal
  // https://us-central1-resly-test-cloudfunctions-net-rico.relay.evervault.dev/resly-test/us-central1/portal

  const evervaultURI =
    process.env.REACT_APP_PORTAL_EVERVAULT_URI ||
    "https://us-central1-resly-pms-production-cloudfunctions-net.relay.evervault.com/portal";

  const url = `${evervaultURI}/reservation/submitOnlineCheckIn`;

  if (profile?.cardNumberRaw) {
    const tidyCC = String(profile?.cardNumberRaw).replaceAll(/[^0-9]/g, "");
    const isVisa = /^4[0-9]{15}$/.test(tidyCC);
    const isMC = /^5[1-5][0-9]{14}$/.test(tidyCC);
    const isAmex = /^3[47][0-9]{13,14}$/.test(tidyCC);
    const cardType = isVisa ? "VI" : isMC ? "MC" : isAmex ? "AX" : "";
    const last4 = String(profile?.cardNumberRaw).slice(-4);
    profile.last4 = last4;
    profile.cardType = cardType;
  }

  const body = {
    propertyId,
    reservationId,
    code,
    profile,
    attachments,
  };

  try {
    const result = await axios.post(url, body, { auth });

    return result.data;
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const uploadReservationFiles = async ({
  idFiles,
  vaxFiles,
  propertyId,
}: {
  idFiles: any;
  vaxFiles: any;
  propertyId: string;
}) => {
  const attachmentsIdFiles = await Promise.all(
    Array.from(idFiles || []).map(async (file: any) => {
      const result: any = await uploadFile({
        hotelId: propertyId,
        file,
        folder: "online-checkin",
      });
      result.isIdFile = true;
      return result;
    })
  );

  const attachmentsVaxFiles = await Promise.all(
    Array.from(vaxFiles || []).map(async (file: any) => {
      const result: any = await uploadFile({
        hotelId: propertyId,
        file,
        folder: "online-checkin",
      });
      result.isVaxFile = true;
      return result;
    })
  );
  return [...attachmentsIdFiles, ...attachmentsVaxFiles];
};
