import dayjs from "dayjs";

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const displayDate = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const displayFileSize = (size: number) => {
  if (size <= 1024) {
    return `${dollarNumber(size)} B`;
  } else if (size > 1024 && size <= 1048576) {
    return `${dollarNumber(size / 1024)} KB`;
  } else {
    return `${dollarNumber(size / 1024 / 1024)} MB`;
  }
};

export const displayTimestamp = (
  timestamp: any,
  format: string = "DD/MM/YYYY h:mm a"
) => {
  const { _seconds = 0 } = timestamp || {};
  if (_seconds > 0) {
    return dayjs(_seconds * 1000).format(format);
  } else {
    return "";
  }

  // const dateFormat = localStorage.getItem("dateFormat") || "YYYY-MM-DD";
  // return timestamp?.toDate
  //   ? dayjs(timestamp.toDate()).format(`${dateFormat} h:mm a`)
  //   : "Error loading time";
};

export const dollarNumber = (number: any) => {
  // return Number(Number(number).toFixed(2));
  return +(+(+number * 100).toFixed(0) / 100);
};

export const verifyCard = (cardNumberRaw: string, paymentMethods: string[]) => {
  const tidyCC = String(cardNumberRaw).replaceAll(/[^0-9]/g, "");
  const isVisa = /^4[0-9]{15}$/.test(tidyCC);
  const isMC = /^5[1-5][0-9]{14}$/.test(tidyCC);
  const isAmex = /^3[47][0-9]{13,14}$/.test(tidyCC);
  const cardType = isVisa ? "visa" : isMC ? "mc" : isAmex ? "amex" : "";

  const isValidType =
    paymentMethods?.length > 0 ? paymentMethods?.includes(cardType) : true;

  return isValidType && (isVisa || isMC || isAmex);
};

export const verifyFlightNo = (flightNo: string) => {
  const flightRegex = /^\w{1,2}?\d{1,4}$/;
  const isFlightNoValid = flightRegex.test(String(flightNo));
  return isFlightNoValid;
};
