import React, { useEffect, useState } from "react";
import {
  CheckCircleIcon,
  MinusIcon,
  PencilAltIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import { uploadInspectionImages } from "../action/actionStaff";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "./Modal";
import FileUpload from "./FileUpload";
import { Button } from "./index";

export const TodoList = ({
  itemName = "task",
  itemList,
  isDescriptionHTML = false,
  editMode = false,
  handleItemChange,
  results,
  propertyId,
}: {
  itemName?: string;
  itemList: any;
  isDescriptionHTML?: boolean;
  editMode: boolean;
  handleItemChange: any;
  results: any;
  propertyId: any;
}) => {
  const itemListSorted = itemList
    .sort((a: any, b: any) => {
      const orderA = a?.order;
      const orderB = b?.order;

      return orderA > orderB ? 1 : -1;
    })
    .map((item: any) => {
      const { id } = item;
      const status = results?.[id]?.status || null;
      return { ...item, status };
    });

  return (
    <fieldset>
      <div className="divide-y divide-gray-200">
        {itemListSorted.length > 0 &&
          itemListSorted.map((item: any) => {
            return (
              <CheckboxListItem
                key={item?.id}
                item={item}
                isDescriptionHTML={isDescriptionHTML}
                editMode={editMode}
                handleItemChange={handleItemChange}
                result={results?.[item?.id]}
                propertyId={propertyId}
              />
            );
          })}
        {itemList.length === 0 && (
          <div className="p-5">No {itemName} is found.</div>
        )}
      </div>
    </fieldset>
  );
};

const CheckboxListItem = ({
  item,
  isDescriptionHTML,
  editMode,
  handleItemChange,
  result,
  propertyId,
}: {
  item: {
    id: string;
    name: string;
    description: string;
    images: any;
    status?: string;
  };
  isDescriptionHTML: boolean;
  editMode: boolean;
  handleItemChange: any;
  result: any;
  propertyId: string;
}) => {
  const { id, name, description, images, status } = item || {};
  const { comments = "", images: commentImages = [] } = result || {};
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    url: "",
    description: "",
  });
  const [expanded, setExpanded] = useState(false);

  const handleImageClick = (image: any) => {
    setSelectedImage(image);
    setImageModalOpen(true);
  };

  const handleCompleteTask = () => {
    handleItemChange({ result: { status: "done" }, itemId: id });
  };

  const handleUncheckTask = () => {
    handleItemChange({ result: { status: null }, itemId: id });
  };

  return (
    <div className="relative items-center">
      <div className="min-w-0 flex-1 text-sm leading-6">
        <div className="flex flex-between px-5 py-2 shadow-sm bg-gray-50">
          <div className={`flex ${editMode ? "cursor-pointer" : ""}`}>
            {editMode &&
              // the hover effect is set only on large screen (computer)
              // otherwise it will be confusing for users to tell it is done or undone task
              (status === "done" ? (
                <CheckCircleIcon
                  className="-ml-0.5 h-5 w-5 mt-0.5 mr-3 text-green-500 lg:hover:text-gray-300 cursor-pointer flex-shrink-0"
                  aria-hidden="true"
                  onClick={handleUncheckTask}
                />
              ) : (
                <CheckCircleIcon
                  className="-ml-0.5 h-5 w-5 mt-0.5 mr-3 text-gray-300 lg:hover:text-green-500 cursor-pointer flex-shrink-0"
                  aria-hidden="true"
                  onClick={handleCompleteTask}
                />
              ))}
            <span
              className={`cursor-pointer font-medium text-gray-900 ${
                status === "done" ? "line-through" : ""
              }`}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {name}
            </span>
          </div>
          <div
            className="cursor-pointer flex-grow text-right flex justify-end mx-3"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? (
              <MinusIcon
                className="-ml-0.5 h-5 w-5 mt-0.5"
                aria-hidden="true"
              />
            ) : (
              <PlusIcon className="-ml-0.5 h-5 w-5 mt-0.5" aria-hidden="true" />
            )}
          </div>
        </div>
        <div className={expanded ? "" : "hidden"}>
          <div className="mt-3 pl-10 pr-5 pb-5">
            {isDescriptionHTML ? (
              <div
                className="text-gray-500"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <p className="text-gray-500">{description}</p>
            )}
            {!!images && (
              <ul
                role="list"
                className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 my-4"
              >
                {Object.values(images).map((image: any, index) => {
                  const { url, description } = image;
                  return (
                    <li key={index} className="relative p-1">
                      <div
                        onClick={() => handleImageClick(image)}
                        className="cursor-pointer border-2 border-gray-300 border-dashed rounded-lg block w-full bg-white overflow-hidden relative"
                        style={{ paddingBottom: "75%" }} // 4:3 aspect ratio
                      >
                        <img
                          src={url}
                          alt={description || ""}
                          className="absolute top-0 left-0 w-full h-full object-cover object-center pointer-events-none"
                        />
                      </div>
                      {!!description && (
                        <div className="text-center">{description}</div>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
            {editMode && (
              <div className="mt-1">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  onClick={() => {
                    setIsNotesModalOpen(true);
                  }}
                >
                  <PencilAltIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="font-bold">Comments</span>
                </button>
                {(!!comments || commentImages.length > 0) && (
                  <div className="bg-yellow-100 rounded-lg p-3 mt-2 w-50">
                    <div className="text-gray-500 whitespace-pre-wrap">
                      {comments}
                    </div>
                    {!!commentImages && (
                      <ul
                        role="list"
                        className="grid grid-cols-2 gap-x-2 gap-y-8 sm:grid-cols-5 sm:gap-x-3 lg:grid-cols-8 xl:gap-x-3"
                      >
                        {commentImages.map((url: string, index: number) => {
                          return (
                            <li key={index} className="relative p-1">
                              <div
                                onClick={() => handleImageClick({ url })}
                                className="cursor-pointer border-2 border-gray-300 border-dashed rounded-lg block w-full bg-white overflow-hidden relative"
                                style={{ paddingBottom: "75%" }} // 4:3 aspect ratio
                              >
                                <img
                                  src={url}
                                  alt={`${name} comments image - ${comments}`}
                                  className="absolute top-0 left-0 w-full h-full object-cover object-center pointer-events-none"
                                />
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ImageModal
        isOpen={isImageModalOpen}
        setOpen={setImageModalOpen}
        url={selectedImage?.url}
        description={selectedImage?.description}
      />
      <AddNotesModal
        isOpen={isNotesModalOpen}
        setOpen={setIsNotesModalOpen}
        itemId={id}
        prevComments={comments}
        prevImages={commentImages}
        handleQuickUpdateInspectionReport={handleItemChange}
        propertyId={propertyId}
      />
    </div>
  );
};

const AddNotesModal = ({
  isOpen,
  setOpen,
  itemId,
  prevComments,
  prevImages,
  handleQuickUpdateInspectionReport,
  propertyId,
}: {
  isOpen: boolean;
  setOpen: any;
  itemId: string;
  prevComments: string;
  prevImages: string[];
  handleQuickUpdateInspectionReport: any;
  propertyId: string;
}) => {
  const [images, setImages] = useState<string[]>(prevImages);
  const [comments, setComments] = useState(prevComments);
  const [isReady, setIsReady] = useState(true);
  const toggle = () => setOpen(!isOpen);

  const handleSaveNotes = () => {
    handleQuickUpdateInspectionReport({ result: { comments, images }, itemId });
    toggle();
  };

  const handleUploadImages = async (files: any) => {
    const urls = await uploadInspectionImages({ files, propertyId });
    await handleQuickUpdateInspectionReport({
      result: { images: [...images, ...urls] },
      itemId,
    });
    setImages([...images, ...urls]);
  };

  const handleDeleteImages = async (url: string) => {
    const newImages = images.filter((imageUrl) => imageUrl !== url);

    await handleQuickUpdateInspectionReport({
      result: { images: newImages },
      itemId,
    });

    setImages(newImages);
  };

  useEffect(() => {
    if (isOpen) {
      setComments(prevComments);
    }
  }, [isOpen]);

  return (
    <>
      <Modal open={isOpen} setOpen={setOpen} size="lg">
        <ModalHeader onClose={toggle}>Add Notes</ModalHeader>
        <ModalBody>
          <div className="mt-1">
            <label className="mb-4 block">Comments</label>
            <textarea
              rows={4}
              name="comment"
              id="comment"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Enter your inspection notes"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
          <div className="mt-6">
            <label className="mb-4 block">Images</label>
            <FileUpload
              uploadFunc={handleUploadImages}
              accept="image/*"
              fileUrls={images}
              handleDeleteFunc={handleDeleteImages}
              setIsReady={setIsReady}
              label="Upload Images"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div></div>
          <Button onClick={handleSaveNotes} disabled={!isReady}>
            Complete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ImageModal = ({
  isOpen,
  setOpen,
  url,
  description,
}: {
  isOpen: boolean;
  setOpen: any;
  url: string;
  description?: string;
}) => {
  const toggle = () => setOpen(!isOpen);

  return (
    <>
      <Modal open={isOpen} setOpen={setOpen}>
        <ModalHeader onClose={toggle}>Image Detail</ModalHeader>
        <ModalBody>
          {<img alt={description || ""} src={url}></img>}
          {!!description && <div className="mt-2">{description}</div>}
        </ModalBody>
      </Modal>
    </>
  );
};
