import { useState, useEffect, useContext } from "react";
import { RadioGroup } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import {
  getStaffVerificationMethods,
  sendCode,
  verifyCode,
} from "../action/actionStaff";
import { PropertyContext } from "../context/property";
import Loading from "../components/Loading";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Login({
  propertyId,
  staffId,
  method: methodQuery,
}: {
  propertyId: string;
  staffId: string;
  method: string;
}) {
  const { handleStaff, isLoading, setLoading } = useContext(PropertyContext);
  const [step, setStep] = useState(methodQuery === "email" ? "code" : "method");
  const [method, setMethod] = useState(methodQuery === "email" ? "email" : "");
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSendCode = async () => {
    setStep("code");
    return sendCode({
      propertyId,
      staffId,
      method,
    });
  };

  const handleVerifyCode = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const { code, verificationCodeExpiry } =
        (await verifyCode({
          propertyId,
          staffId,
          verificationCode,
        })) || {};

      // setLoading(false);

      localStorage.setItem("code", code);
      localStorage.setItem("codeExpiry", verificationCodeExpiry);

      return handleStaff();
    } catch (error) {
      setLoading(false);
      setVerificationCode("");
      setErrorMessage("The code is incorrect.");
    }
  };

  useEffect(() => {
    if (methodQuery === "email") {
      handleSendCode();
    }
  }, [methodQuery]);

  return (
    <>
      {isLoading && <Loading />}
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray-500 transition-opacity" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            {step === "method" && (
              <VerificationSelectMethod
                propertyId={propertyId}
                staffId={staffId}
                method={method}
                setMethod={setMethod}
                handleSendCode={handleSendCode}
              />
            )}
            {step === "code" && (
              <EnterVerificationCode
                setStep={setStep}
                verificationCode={verificationCode}
                setVerificationCode={setVerificationCode}
                handleVerifyCode={handleVerifyCode}
                errorMessage={errorMessage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const VerificationSelectMethod = ({
  propertyId,
  staffId,
  method,
  setMethod,
  handleSendCode,
}: {
  propertyId: string;
  staffId: string;
  method: string;
  setMethod: any;
  handleSendCode: any;
}) => {
  const { setLoading, setLoggedIn, property } = useContext(PropertyContext);
  const { name: propertyName, avatar } = property || {};
  const [options, setOptions]: [any, any] = useState({});

  useEffect(() => {
    const handleOptions = async () => {
      try {
        const options2 = await getStaffVerificationMethods({
          propertyId,
          staffId,
        });
        setOptions(options2);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setLoggedIn(false);
      }
    };
    handleOptions();
  }, []);

  const isReady = !!method;

  return (
    <>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <img
            className="block h-20 rounded w-auto mb-5 mx-auto"
            src={avatar}
            alt={propertyName}
          />
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {propertyName}
          </h3>
          <hr className="my-8" />
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Staff Portal
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Select a verification method below to continue
            </p>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <RadioGroup value={method} onChange={setMethod}>
          <RadioGroup.Label className="sr-only">
            Verification Method
          </RadioGroup.Label>
          <div className="space-y-4">
            {options?.mobile && (
              <RadioGroup.Option
                value="mobile"
                className={({ active }) =>
                  classNames(
                    active ? "ring-1 ring-offset-2 ring-indigo-500" : "",
                    "relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
                  )
                }
              >
                {({ checked }) => (
                  <>
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className="font-medium text-gray-900"
                        >
                          Mobile
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="div"
                          className="text-gray-500"
                        >
                          <p className="sm:inline">{options?.mobile}</p>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        checked ? "border-indigo-500" : "border-transparent",
                        "absolute -inset-px rounded-lg border-2 pointer-events-none"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            )}

            {options?.email && (
              <RadioGroup.Option
                value="email"
                className={({ active }) =>
                  classNames(
                    active ? "ring-1 ring-offset-2 ring-indigo-500" : "",
                    "relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
                  )
                }
              >
                {({ checked }) => (
                  <>
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className="font-medium text-gray-900"
                        >
                          Email
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="div"
                          className="text-gray-500"
                        >
                          <p className="sm:inline">{options?.email}</p>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        checked ? "border-indigo-500" : "border-transparent",
                        "absolute -inset-px rounded-lg border-2 pointer-events-none"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            )}
          </div>
        </RadioGroup>
      </div>
      <div className="mt-5 sm:mt-6">
        {isReady && (
          <button
            onClick={isReady ? handleSendCode : null}
            type="button"
            className={`${
              isReady
                ? "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                : "bg-gray-300"
            } inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium  sm:text-sm`}
          >
            Get Verification Code
          </button>
        )}
      </div>
    </>
  );
};

const EnterVerificationCode = ({
  setStep,
  verificationCode,
  setVerificationCode,
  handleVerifyCode,
  errorMessage,
}: {
  setStep: any;
  handleVerifyCode: any;
  verificationCode: string;
  setVerificationCode: any;
  errorMessage: string;
}) => {
  return (
    <>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Verification Code
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              We've sent you a verification code
            </p>
            <p className="text-xs text-gray-400 mt-2">
              Haven't received the code? Try checking your spam/junk folders
            </p>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <input
          type="text"
          name="verificationCode"
          id="verificationCode"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="Enter the verification code"
        />
      </div>
      {errorMessage && <Alert message={errorMessage} />}
      <div className="mt-5 sm:mt-6">
        <button
          onClick={handleVerifyCode}
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        >
          Continue
        </button>
      </div>
    </>
  );
};

const Alert = ({ message }: { message: string }) => {
  return (
    <div className="rounded-md bg-red-50 p-4 mt-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{message}</h3>
        </div>
      </div>
    </div>
  );
};
