import {
  ChevronLeftIcon,
  LoginIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { displayDate } from "../action";
import {
  completeHousekeepingTask,
  getHousekeepingTaskDetails,
  getHousekeepingTasks,
  getOverdueHousekeepingTasks,
  startHousekeepingTask,
  updateHousekeepingTask,
  uploadHousekeepingImages,
} from "../action/actionStaff";
import useSubmitWithToast from "../action/useSubmitWithToast";
import { Button } from "../components";
import { LoadingContent } from "../components/Loading";
import { PropertyContext } from "../context/property";
import { TodoList } from "../components/TodoList";
import FileUpload from "../components/FileUpload";
import Badge from "../components/Badge";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Housekeeping({
  propertyId,
  staffId,
}: {
  propertyId: string;
  staffId: string;
}) {
  const [selectedTaskId, setSelectedTaskId] = useState("");

  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            Housekeeping
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800 ml-2">
              BETA
            </span>
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-8 sm:px-0">
            {selectedTaskId ? (
              <HousekeepingSingleTask
                housekeepingId={selectedTaskId}
                propertyId={propertyId}
                staffId={staffId}
                setSelectedTaskId={setSelectedTaskId}
              />
            ) : (
              <HousekeepingTaskList
                propertyId={propertyId}
                staffId={staffId}
                setSelectedTaskId={setSelectedTaskId}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
}

const HousekeepingTaskList = ({
  propertyId,
  staffId,
  setSelectedTaskId,
}: {
  propertyId: string;
  staffId: string;
  setSelectedTaskId: any;
}) => {
  const [isLoading, setLoading] = useState(true);
  const { setLoggedIn } = useContext(PropertyContext);
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState("today");

  const tabs = [
    { value: "today", name: "Today" },
    { value: "tomorrow", name: "Tomorrow" },
    { value: "next7days", name: "Next 7 days" },
    { value: "next30days", name: "Next 30 days" },
    { value: "next90days", name: "Next 90 days" },
  ];

  const handleTasks = async () => {
    const code = localStorage.getItem("code") || "";
    setTasks([]);
    setLoading(true);

    try {
      if (activeTab === "today") {
        const tasks2 = await getOverdueHousekeepingTasks({
          propertyId,
          staffId,
          code,
        });
        setTasks(tasks2);
      }

      if (activeTab === "tomorrow") {
        const tomorrow = dayjs().add(1, "days").format("YYYY-MM-DD");
        const tasks2 = await getHousekeepingTasks({
          propertyId,
          staffId,
          code,
          startDate: tomorrow,
          endDate: tomorrow,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next7days") {
        const startDate = dayjs().add(1, "days").format("YYYY-MM-DD");
        const endDate = dayjs().add(7, "days").format("YYYY-MM-DD");
        const tasks2 = await getHousekeepingTasks({
          propertyId,
          staffId,
          code,
          startDate,
          endDate,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next30days") {
        const startDate = dayjs().add(1, "days").format("YYYY-MM-DD");
        const endDate = dayjs().add(30, "days").format("YYYY-MM-DD");
        const tasks2 = await getHousekeepingTasks({
          propertyId,
          staffId,
          code,
          startDate,
          endDate,
        });
        setTasks(tasks2);
      }

      if (activeTab === "next90days") {
        const startDate = dayjs().add(1, "days").format("YYYY-MM-DD");
        const endDate = dayjs().add(90, "days").format("YYYY-MM-DD");
        const tasks2 = await getHousekeepingTasks({
          propertyId,
          staffId,
          code,
          startDate,
          endDate,
        });
        setTasks(tasks2);
      }
      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTasks();
  }, [activeTab]);

  return (
    <>
      {/* Tabs */}
      <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
        <div className="mb-4 sm:mb-0">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.value} value={tab.value}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <span
                  key={tab.value}
                  onClick={() => setActiveTab(tab.value)}
                  className={classNames(
                    tab.value === activeTab
                      ? "bg-indigo-100 text-indigo-700"
                      : "text-gray-500 hover:text-gray-700",
                    "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={tab.value === activeTab ? "page" : undefined}
                >
                  {tab.name}
                </span>
              ))}
            </nav>
          </div>
        </div>

        <div>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
            {tasks?.length} tasks
          </span>
        </div>
      </div>
      {/* Today */}

      {isLoading ? (
        <LoadingContent />
      ) : (
        <HousekeepingTable
          tasks={tasks}
          setSelectedTaskId={setSelectedTaskId}
          showDate={activeTab !== "today" && activeTab !== "tomorrow"}
          staffId={staffId}
        />
      )}
    </>
  );
};

const HousekeepingTable = ({
  tasks,
  setSelectedTaskId,
  showDate,
  staffId,
}: {
  tasks: any;
  setSelectedTaskId: any;
  showDate: boolean;
  staffId: string;
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          {showDate && (
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Date
            </th>
          )}
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Room
          </th>
          <th
            scope="col"
            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Housekeeping Type
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          ></th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {tasks
          .sort((a: any, b: any) => {
            // put urgent tasks first
            // then overdue tasks
            // then sort by order
            const urgentComparison = (b?.isUrgent || 0) - (a?.isUrgent || 0);
            if (urgentComparison !== 0) return urgentComparison;

            const dateComparison = dayjs(a?.date).diff(dayjs(b?.date));
            if (dateComparison !== 0) return dateComparison;

            // if order is not set for some of the tasks, put it in the last
            const orderComparison =
              (a?.order !== undefined ? a?.order : 999) -
              (b?.order !== undefined ? b?.order : 999);
            if (orderComparison !== 0) return orderComparison;

            return a?.roomName?.localeCompare(b?.roomName);
          })
          .map((task: any) => (
            <HousekeepingTableRow
              task={task}
              key={task.id}
              setSelectedTaskId={setSelectedTaskId}
              showDate={showDate}
              staffId={staffId}
            />
          ))}
      </tbody>
    </table>
  );
};

const HousekeepingTableRow = ({
  task,
  setSelectedTaskId,
  showDate,
  staffId,
}: {
  task: any;
  setSelectedTaskId: any;
  showDate: boolean;
  staffId: string;
}) => {
  const today = dayjs().format("YYYY-MM-DD");
  const tomorrow = dayjs().add(1, "days").format("YYYY-MM-DD");
  const {
    id,
    date,
    roomName,
    roomTypeName,
    type,
    isStarted,
    isEnded,
    currentReservation,
    nextReservation,
    assignTo,
    isUrgent,
    order,
  } = task;

  const { checkIn } = nextReservation || {};
  const { checkOut, isCheckedOut, departureTime } = currentReservation || {};

  const isInProgress = !!isStarted && !isEnded;
  const isArrivingToday = checkIn === today;
  const isArrivingTomorrow = checkIn === tomorrow;
  const isDepartingToday = checkOut === today;
  const isBackToBack = isArrivingToday && isDepartingToday;
  const isOverDue = today > date;

  return (
    <tr key={id} className={isUrgent ? "bg-red-50" : ""}>
      {showDate && (
        <td className="px-3 py-4 text-sm text-gray-700">{displayDate(date)}</td>
      )}

      <td className="px-3 py-4 text-sm text-gray-700">
        {order !== undefined && !showDate && (
          <span className="inline-flex items-center px-1 py-0.5 mr-1 rounded text-xs font-medium bg-purple-100 text-purple-800">
            {order + 1}
          </span>
        )}
        {!!roomName ? (
          roomName
        ) : (
          <span className="text-gray-400">No Room Selected</span>
        )}
        {isInProgress && (
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 ml-2">
            <svg
              className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx={4} cy={4} r={3} />
            </svg>
            In Progress
          </span>
        )}
        <dd className="text-gray-500 text-xs mt-1">{roomTypeName}</dd>
        <dl className="font-normal sm:hidden">
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            <BadgeHousekeepingType task={task} />
          </dd>
          {type === "depart-clean" && (
            <dd className="mt-1">
              {isCheckedOut ? (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 mr-1">
                  Checked Out
                </span>
              ) : !!departureTime ? (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800 mr-1">
                  Departs {departureTime}
                </span>
              ) : null}
              {isBackToBack ? (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                  Back to Back
                </span>
              ) : isDepartingToday ? (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                  Departing Today
                </span>
              ) : isArrivingToday ? (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                  Arriving Today
                </span>
              ) : null}
            </dd>
          )}
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <dd>
          <BadgeHousekeepingType task={task} />
          {isUrgent && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-500 text-white ml-2">
              Urgent
            </span>
          )}
          {isOverDue && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 ml-2">
              Overdue {displayDate(date)}
            </span>
          )}
        </dd>
        {type === "depart-clean" && (
          <dd className="mt-1">
            {isCheckedOut ? (
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 mr-1">
                Checked Out
              </span>
            ) : !!departureTime ? (
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800 mr-1">
                Departs between {departureTime}
              </span>
            ) : isDepartingToday ? (
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                Departing Today
              </span>
            ) : null}
            {isBackToBack ? (
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                Back to Back
              </span>
            ) : isArrivingToday ? (
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                Arriving Today
              </span>
            ) : isArrivingTomorrow ? (
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                Arrive Tomorrow
              </span>
            ) : null}
          </dd>
        )}
        {assignTo !== staffId && (
          <dd className="mt-1">
            <Badge>Assigned to Another</Badge>
          </dd>
        )}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500"></td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <a
          href="#"
          className="text-indigo-600 hover:text-indigo-900"
          onClick={() => setSelectedTaskId(id)}
        >
          Details
        </a>
      </td>
    </tr>
  );
};

const HousekeepingSingleTask = ({
  housekeepingId,
  propertyId,
  staffId,
  setSelectedTaskId,
}: {
  housekeepingId: string;
  propertyId: string;
  staffId: string;
  setSelectedTaskId: any;
}) => {
  const [isLoading, setLoading] = useState(true);
  const { staff, setLoggedIn } = useContext(PropertyContext);
  const [task, setTask]: [any, any] = useState(null);
  const [summaryImages, setSummaryImages] = useState<any[]>([]);
  const [comments, setComments] = useState("");
  const [showIncomingGuest, setShowIncomingGuest] = useState(false);
  const [isReady, setIsReady] = useState(true);

  const summaryImageUrls = summaryImages.map((image: any) => image?.url);

  const {
    task: taskItem,
    nextReservation,
    currentReservation,
    guide = {},
    roomDefaultHousekeeper,
  } = task || {};

  const guideList = Object.entries(guide)
    .map(([id, guideItem]) => {
      if (typeof guideItem === "object") {
        return { id, ...guideItem };
      }
    })
    // filter guide without the name
    // a name is a mandatory field  for guide
    // but edge case is when user set up guide
    // they upload the image but not save the guide
    .filter((guide: any) => !!guide?.name);

  const {
    id: taskId,
    date,
    type,
    isStarted,
    isEnded,
    roomName,
    roomId,
    roomTypeName,
    status,
    results = {},
    attachments: prevAttachments = [],
    assignTo,
    name,
    description,
    tenancyId,
    roomNotes,
    timeAllocation,
  } = taskItem || {};

  const {
    id: nextResId,
    firstName,
    lastName,
    notesHousekeeping,
    checkIn,
    checkOut,
    adults,
    children = 0,
    arrivalTime,
    bedConfigName,
    ref,
    lockOffBedrooms,
  } = nextReservation || {};

  const {
    id: currentResId,
    firstName: firstNameCurrent,
    lastName: lastNameCurrent,
    notesHousekeeping: notesHousekeepingCurrent,
    checkIn: checkInCurrent,
    checkOut: checkOutCurrent,
    adults: adultsCurrent,
    children: childrenCurrent = 0,
    bedConfigName: bedConfigNameCurrent,
    ref: refCurrent,
    departureTime,
    lockOffBedrooms: lockOffBedroomsCurrent,
    isCheckedOut: isCheckedOutCurrent,
  } = currentReservation || {};

  const today = dayjs().format("YYYY-MM-DD");
  const tomorrow = dayjs().add(1, "days").format("YYYY-MM-DD");
  const isTaskDue = today >= date && status === "scheduled";
  // The default housekeeper for a room will always see the task.
  // If the task is specifically assigned to someone,
  // then it must be that staff to start the task.
  // But if it's not assigned, the default housekeeper can start the task.
  const canStaffStartTask = !!assignTo
    ? assignTo === staffId
    : roomDefaultHousekeeper === staffId;

  const isInProgress = !!isStarted && !isEnded;
  const isArrivingToday = today === checkIn;
  const isArrivingTomorrow = tomorrow === checkIn;
  const isDepartingToday = today === checkOutCurrent;

  const nextArrivalFromToday = dayjs(checkIn).diff(today, "days");
  const los = dayjs(checkOut).diff(checkIn, "days");
  const losCurrent = dayjs(checkOutCurrent).diff(checkInCurrent, "days");

  const handleHousekeepingTask = async () => {
    try {
      setLoading(true);
      const task2 = await getHousekeepingTaskDetails({
        propertyId,
        housekeepingId,
        staffId,
      });

      const isArrivingLaterThanTomorrow =
        dayjs().add(1, "days").format("YYYY-MM-DD") <
        task2?.nextReservation?.checkIn;
      if (!isArrivingLaterThanTomorrow) {
        setShowIncomingGuest(true);
      }
      setTask(task2);
      setLoading(false);
    } catch (error) {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  const handleHousekeepingTodoItemChange = async ({
    result,
    itemId,
  }: {
    result: any;
    itemId: string;
  }) => {
    const newResults = {
      ...task?.results,
      [itemId]: { ...task?.results?.[itemId], ...result },
    };

    setTask({
      ...task,
      task: {
        ...task?.task,
        results: {
          ...task?.task?.results,
          [itemId]: { ...task?.task?.results?.[itemId], ...result },
        },
      },
    });

    try {
      await updateHousekeepingTask({
        propertyId,
        staffId,
        housekeepingId,
        fieldName: "results",
        fieldValue: newResults,
      });
    } catch (err: any) {
      alert(err?.message);
      throw Error(err?.message);
    }
    return Promise.resolve();
  };

  const handleUploadSummaryImages = async (files: any) => {
    const attachments = await uploadHousekeepingImages({ files, propertyId });
    const newAttachments = [...summaryImages, ...attachments];
    setSummaryImages(newAttachments);

    await updateHousekeepingTask({
      propertyId,
      staffId,
      housekeepingId,
      fieldName: "attachments",
      fieldValue: newAttachments,
    });
  };

  const handleDeleteSummaryImage = async (url: any) => {
    const newAttachments = summaryImages.filter(
      (attachment: any) => attachment?.url !== url
    );

    await updateHousekeepingTask({
      propertyId,
      staffId,
      housekeepingId,
      fieldName: "attachments",
      fieldValue: newAttachments,
    });

    setSummaryImages(newAttachments);
  };

  const [handleStartTask] = useSubmitWithToast({
    func: () => {
      return startHousekeepingTask({
        propertyId,
        housekeepingId,
        staffId,
        roomId,
        guide,
      });
    },
    successMessage: "The task starts now.",
    successFunc: () => {
      handleHousekeepingTask();
    },
  });

  const [handleCompleteTask] = useSubmitWithToast({
    func: async () => {
      try {
        await completeHousekeepingTask({
          propertyId,
          housekeepingId,
          staffId,
          files: summaryImages,
          comments,
          staff,
          task: taskItem,
        });
      } catch (err: any) {
        alert(err?.message);
        throw Error(err?.message);
      }

      return Promise.resolve();
    },
    successMessage: "The task is successfully completed.",
    successFunc: () => {
      handleHousekeepingTask();
      setSelectedTaskId("");
    },
    loadingText: "Submitting your housekeeping task record...",
  });

  useEffect(() => {
    handleHousekeepingTask();
  }, [housekeepingId]);

  useEffect(() => {
    setSummaryImages(prevAttachments);
  }, [task]);

  return (
    <div className="">
      {isLoading ? (
        <LoadingContent />
      ) : (
        <>
          <button
            type="button"
            className="mb-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setSelectedTaskId("")}
          >
            <ChevronLeftIcon className="h-4 w-4 mr-1" /> Go back
          </button>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div
              className={`px-4 py-5 sm:px-6 ${
                isInProgress ? "text-white bg-green-500" : "text-auto bg-white"
              }`}
            >
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div>
                  <h3
                    className={`text-lg leading-6 font-medium ${
                      !isInProgress ? "text-gray-700" : "text-white"
                    }`}
                  >
                    {!!roomName ? roomName : name}
                  </h3>
                  <p
                    className={`mt-1 max-w-2xl text-sm ${
                      !isInProgress ? "text-gray-500" : "text-white"
                    }`}
                  >
                    {roomTypeName}
                  </p>
                </div>

                <div>
                  <dd
                    className={`${
                      isInProgress ? "text-white " : "text-indigo-600"
                    } mb-1`}
                  >
                    <BadgeHousekeepingType task={task?.task} />
                  </dd>
                  <dd
                    className={`${
                      isInProgress ? "text-white " : "text-indigo-600"
                    } text-xl sm:text-right mb-1`}
                  >
                    <Countdown task={task} />
                  </dd>
                </div>
              </div>
              {!!roomNotes && (
                <div className="mt-2">
                  <div className="rounded bg-yellow-100 py-2 px-4 whitespace-pre-wrap text-gray-800">
                    {roomNotes}
                  </div>
                </div>
              )}
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                {type !== "adhoc" && (
                  <div className="py-4 px-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 mb-4">
                      {type === "depart-clean" && (
                        <span>
                          <LogoutIcon className="h-4 w-4 mr-2 inline-block" />
                          <span>Outgoing Guests</span>
                        </span>
                      )}
                      {type === "service-clean" && <span>Current Guests</span>}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div>
                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                          {refCurrent}
                        </span>
                        <span>{`${lastNameCurrent}, ${firstNameCurrent}`}</span>
                        <span className="text-gray-500">{` (${adultsCurrent}A${
                          childrenCurrent || 0
                        }C)`}</span>
                        {lockOffBedroomsCurrent > 0 && (
                          <span className="ml-2 text-indigo-500">
                            {lockOffBedroomsCurrent}B
                          </span>
                        )}
                      </div>
                      <div className="mt-2">
                        <span>
                          {type === "depart-clean" && (
                            <>
                              {isCheckedOutCurrent ? (
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 mr-1">
                                  Checked Out
                                </span>
                              ) : !!departureTime ? (
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800 mr-1">
                                  Departs between {departureTime}
                                </span>
                              ) : isDepartingToday ? (
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                                  Departing Today
                                </span>
                              ) : null}
                            </>
                          )}

                          {/* {!!isCheckedOutCurrent ? (
                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 mr-1">
                            Checked Out
                          </span>
                        ) : (
                          isDepartingToday &&
                          type === "depart-clean" && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 mr-1">
                              Departing Today
                            </span>
                          )
                        )} */}

                          {`${displayDate(checkInCurrent)} to ${displayDate(
                            checkOutCurrent
                          )}`}
                        </span>
                        <span className="text-gray-500">{` (${losCurrent} nights)`}</span>
                      </div>
                      {/* {type === "depart-clean" && !!departureTime && (
                      <dd className="mt-2">Departure Time: {departureTime}</dd>
                    )} */}

                      {!!bedConfigNameCurrent && (
                        <div className="mt-2">
                          Bedding: {bedConfigNameCurrent}
                        </div>
                      )}

                      {!!notesHousekeepingCurrent && (
                        <div className="mt-2">
                          <div className="rounded bg-yellow-100 py-2 px-4 whitespace-pre-wrap">
                            {notesHousekeepingCurrent}
                          </div>
                        </div>
                      )}
                    </dd>
                  </div>
                )}

                {type === "depart-clean" && (
                  <div className="py-4 px-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 mb-4">
                      <span>
                        <LoginIcon className="h-4 w-4 mr-2 inline-block" />
                        <span>Incoming Guests</span>
                      </span>
                    </dt>
                    {showIncomingGuest ? (
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div>
                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                            {ref}
                          </span>
                          <span>{`${lastName}, ${firstName}`}</span>
                          <span className="text-gray-500">{` (${adults}A${
                            children || 0
                          }C)`}</span>
                          {lockOffBedrooms > 0 && (
                            <span className="ml-1 text-indigo-500">
                              {lockOffBedrooms}B
                            </span>
                          )}
                        </div>
                        <div className="mt-2">
                          {isArrivingToday ? (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 mr-1">
                              Arriving Today
                            </span>
                          ) : isArrivingTomorrow ? (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                              Arrive Tomorrow
                            </span>
                          ) : null}

                          <span>{`${displayDate(checkIn)} to ${displayDate(
                            checkOut
                          )}`}</span>
                          <span className="text-gray-500">{` (${los} nights)`}</span>
                        </div>

                        {!!arrivalTime && (
                          <div className="mt-2">
                            Arrival Time: {arrivalTime}
                          </div>
                        )}

                        {!!bedConfigName && (
                          <div className="mt-2">Bedding: {bedConfigName}</div>
                        )}

                        {!!notesHousekeeping && (
                          <div className="mt-2">
                            <div className="rounded bg-yellow-100 text-gray-700 py-2 px-4">
                              {notesHousekeeping}
                            </div>
                          </div>
                        )}
                      </dd>
                    ) : (
                      <dd>
                        <div className="text-sm font-medium text-gray-500 mb-2">
                          Next guest arrives in {nextArrivalFromToday} days
                        </div>

                        <span
                          onClick={() => setShowIncomingGuest(true)}
                          className="text-sm text-indigo-600 cursor-pointer"
                        >
                          Show incoming guests
                        </span>
                      </dd>
                    )}
                  </div>
                )}

                {type === "adhoc" && (
                  <div className="py-4 px-5 sm:py-5 ">
                    {!!roomName && (
                      <div
                        className="leading-6 font-medium
                        text-gray-700"
                      >
                        {name}
                      </div>
                    )}
                    <span className="text-sm font-medium text-gray-500 mb-2 whitespace-pre-line">
                      {description}
                    </span>
                  </div>
                )}

                <div className="border-t border-gray-200 p-0">
                  <dl className="divide-y divide-gray-200">
                    {guideList.length > 0 && (
                      <TodoList
                        itemList={guideList}
                        isDescriptionHTML={true}
                        editMode={isInProgress}
                        handleItemChange={handleHousekeepingTodoItemChange}
                        results={results}
                        propertyId={propertyId}
                      />
                    )}
                    {isTaskDue && canStaffStartTask && (
                      <>
                        {isInProgress ? (
                          <>
                            <div className="flex flex-col px-8 justify-center items-center sm:items-start">
                              <div className="mt-4 w-full">
                                <label className="mb-2 block text-center sm:text-left">
                                  General Comments
                                </label>
                                <textarea
                                  rows={4}
                                  name="comment"
                                  id="comment"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  placeholder="Enter your inspection comments"
                                  value={comments}
                                  onChange={(e) => setComments(e.target.value)}
                                />
                              </div>
                              <div className="my-6 w-full">
                                <label className="mb-2 block text-center sm:text-left">
                                  Images
                                </label>

                                <FileUpload
                                  uploadFunc={handleUploadSummaryImages}
                                  accept="image/*"
                                  fileUrls={summaryImageUrls}
                                  handleDeleteFunc={handleDeleteSummaryImage}
                                  setIsReady={setIsReady}
                                  label="Upload Images"
                                />
                              </div>
                            </div>

                            <div className="py-4 flex-col text-center">
                              <Button
                                onClick={handleCompleteTask}
                                className="mx-auto"
                                disabled={!isReady}
                              >
                                Complete
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="py-4 grid place-content-center">
                            <Button onClick={handleStartTask}>
                              Start Task
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </dl>
                </div>

                {/*{isTaskDue && (*/}
                {/*  <>*/}
                {/*    {isInProgress ? (*/}
                {/*      <>*/}
                {/*        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">*/}
                {/*          <dt className="text-sm font-medium text-gray-500">*/}
                {/*            Actions*/}
                {/*          </dt>*/}
                {/*          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">*/}
                {/*            <div className="mt-1">*/}
                {/*              <label className="mb-4 block">Comments</label>*/}
                {/*              <textarea*/}
                {/*                rows={4}*/}
                {/*                name="comment"*/}
                {/*                id="comment"*/}
                {/*                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"*/}
                {/*                placeholder="Enter your housekeeping comments"*/}
                {/*                value={comments}*/}
                {/*                onChange={(e) => setComments(e.target.value)}*/}
                {/*              />*/}
                {/*            </div>*/}
                {/*            <div className="mt-6">*/}
                {/*              <label className="mb-4 block">Images</label>*/}
                {/*              <label*/}
                {/*                htmlFor="files"*/}
                {/*                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600"*/}
                {/*              >*/}
                {/*                /!* <span>Upload files</span> *!/*/}
                {/*                <span className="inline-flex items-center py-2 px-6 border border-transparent rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
                {/*                  <UploadIcon*/}
                {/*                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"*/}
                {/*                    aria-hidden="true"*/}
                {/*                  />*/}
                {/*                  <span className="text-sm font-medium text-gray-900">*/}
                {/*                    Upload Files*/}
                {/*                  </span>*/}
                {/*                </span>*/}
                {/*                <input*/}
                {/*                  id="files"*/}
                {/*                  name="files"*/}
                {/*                  type="file"*/}
                {/*                  className="sr-only"*/}
                {/*                  onChange={(e: any) => {*/}
                {/*                    setFiles(e.target.files);*/}
                {/*                  }}*/}
                {/*                  multiple*/}
                {/*                  accept="image/*"*/}
                {/*                />*/}
                {/*              </label>*/}
                {/*            </div>*/}

                {/*            <ul*/}
                {/*              role="list"*/}
                {/*              className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-4"*/}
                {/*            >*/}
                {/*              {Array.from(files || [])?.map((file) => (*/}
                {/*                <li className="relative border-2 border-gray-300 border-dashed rounded-lg p-1">*/}
                {/*                  <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-white overflow-hidden">*/}
                {/*                    <img*/}
                {/*                      src={URL.createObjectURL(file)}*/}
                {/*                      alt=""*/}
                {/*                      className="object-cover pointer-events-none"*/}
                {/*                    />*/}
                {/*                  </div>*/}
                {/*                </li>*/}
                {/*              ))}*/}
                {/*            </ul>*/}
                {/*          </dd>*/}
                {/*        </div>*/}
                {/*        <div className="py-4 grid place-content-center">*/}
                {/*          <Button onClick={handleCompleteTask}>Complete</Button>*/}
                {/*        </div>*/}
                {/*      </>*/}
                {/*    ) : (*/}
                {/*      <div className="py-4 grid place-content-center">*/}
                {/*        <Button onClick={handleStartTask}>Start Task</Button>*/}
                {/*      </div>*/}
                {/*    )}*/}
                {/*  </>*/}
                {/*)}*/}
              </dl>
            </div>
          </div>
          <div className="text-xs text-gray-500 mt-6 flex flex-col md:flex-row">
            <span className="mr-4">Task ID: {housekeepingId}</span>
            {type !== "adhoc" && (
              <>
                <span className="mr-4">Current Res ID: {currentResId}</span>
                <span className="mr-4">Next Res ID: {nextResId}</span>
              </>
            )}
            {!!tenancyId && (
              <span className="mr-4">Tenancy ID: {tenancyId}</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const Countdown = ({ task }: { task: any }) => {
  const [time, setTime] = useState(0);
  const { isStarted, isEnded, timestampStarted, timeAllocation } =
    task?.task || {};

  const isInProgress = !!isStarted && !isEnded;

  useEffect(() => {
    if (timestampStarted?._seconds && isInProgress) {
      const duration = -dayjs
        .unix(timestampStarted?._seconds)
        .diff(new Date(), "seconds");
      if (duration >= 0) {
        const tick = () => setTime(+(duration || 0) + 1);
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
      }
    }
  }, [timestampStarted?._seconds, time]);

  // useEffect(() => {
  //   return () => setTime(0);
  // }, [time]);

  const minutes = Math.floor(time / 60);
  // const seconds = time - minutes * 60;

  if (!isInProgress) {
    return !!timeAllocation ? <span>{`${timeAllocation} mins`}</span> : null;
  }

  return (
    <span>
      {!!timeAllocation
        ? `${minutes}/${timeAllocation} mins`
        : `${minutes} mins`}
    </span>
  );
};

export const BadgeHousekeepingType = ({ task }: { task: any }) => {
  const { name, type, isDailyServiceClean } = task || {};
  if (type === "adhoc") {
    return (
      <span>
        <span>{name}</span>
      </span>
    );
  }

  if (type === "depart-clean") {
    return (
      <span>
        <span className="inline-flex items-center px-1 py-0.5 mr-1 rounded text-xs font-medium bg-blue-100 text-blue-800">
          D
        </span>
        <span>Depart Clean</span>
      </span>
    );
  }

  if (type === "service-clean") {
    if (isDailyServiceClean) {
      return (
        <span>
          <span className="inline-flex items-center px-1 py-0.5 mr-1 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            d
          </span>
          <span>Daily Service Clean</span>
        </span>
      );
    } else {
      return (
        <span>
          <span className="inline-flex items-center px-1 py-0.5 mr-1 rounded text-xs font-medium bg-purple-100 text-purple-800">
            S
          </span>
          <span>Service Clean</span>
        </span>
      );
    }
  }

  return <span>{type}</span>;
};
