export default function Footer({ property }: { property: any }) {
  const { name, phone, email, websiteLink } = property || {};
  return (
    <footer className="bg-gray-800 text-sm text-gray-400">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <a href={`tel://${phone}`} target="_blank" rel="noreferrer">
            {phone}
          </a>
        </div>
        <div className="flex justify-center space-x-6 md:order-2">
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            {email}
          </a>
        </div>
        <div className="mt-4 md:mt-0 md:order-1">
          <p className="text-center">
            <span>&copy;</span>
            <a href={websiteLink} target="_blank" rel="noreferrer">
              {` ${name}. `}
            </a>
            <span>All rights reserved.</span>
          </p>
        </div>
      </div>
    </footer>
  );
}
