import { XCircleIcon } from "@heroicons/react/outline";
import { classNames } from "../action";

export const Button = ({
  children,
  disabled,
  className,
  ...props
}: {
  children: any;
  disabled?: boolean;
  className?: string;
  [key: string]: any;
}) => {
  const classNameFinal = `inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white ${
    disabled
      ? "bg-indigo-200 hover:bg-indigo-200 cursor-not-allowed"
      : "bg-indigo-600 hover:bg-indigo-700"
  } ${className}`;
  // focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500

  return (
    <button type="button" className={classNameFinal} {...props}>
      {children}
    </button>
  );
};

export const Input = ({
  type = "input",
  name,
  className,
  children,
  errors,
  hint,
  ...props
}: {
  type?: string;
  name: string;
  className?: string;
  children?: any;
  errors?: any;
  hint?: string;
  [k: string]: any;
}) => {
  const isValid = !errors?.[name];
  const validClassName = {
    invalid:
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500",
    valid:
      "border-indigo-500-focus border-gray-300 ring-0 focus:ring-indigo-500 focus:border-indigo-500 appearance-none",
  };

  if (type === "checkbox") {
    return (
      <>
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              id={name}
              name={name}
              className={`h-4 w-4 text-indigo-500 ${
                isValid ? validClassName.valid : validClassName.invalid
              } rounded`}
              {...props}
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor={name}
              className={`font-medium ${
                isValid ? "text-gray-700" : "text-red-500"
              } `}
            >
              {props?.label || ""}
            </label>
          </div>
        </div>
      </>
    );
  }

  if (type === "select") {
    return (
      <>
        <select
          name={name}
          className={`block w-full shadow-sm sm:text-sm rounded-md ${
            isValid ? validClassName.valid : validClassName.invalid
          } ${className}`}
          {...props}
        >
          {children}
        </select>
        {errors?.[name] && (
          <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
            {errors?.[name]}
          </p>
        )}
      </>
    );
  }
  if (type === "textarea") {
    return (
      <textarea
        name={name}
        className={`block w-full shadow-sm sm:text-sm rounded-md ${
          isValid ? validClassName.valid : validClassName.invalid
        } ${className}`}
        {...props}
      />
    );
  }
  return (
    <>
      <input
        type={type}
        name={name}
        className={`block w-full shadow-sm sm:text-sm rounded-md ${
          isValid ? validClassName.valid : validClassName.invalid
        } ${className}`}
        {...props}
      />
      {errors?.[name] && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          {errors?.[name]}
        </p>
      )}
      {hint && !errors?.[name] && (
        <p className="mt-2 text-sm text-gray-400" id={`${name}-hint`}>
          {hint}
        </p>
      )}
    </>
  );
};

export const EmptyState = ({ text = "Nothing Found" }) => {
  return (
    <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center">
      <XCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
      <span className="mt-2 block text-sm font-medium text-gray-900">
        {text}
      </span>
    </div>
  );
};
