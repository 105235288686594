import { useContext } from "react";
import { useToasts } from "react-toast-notifications";
import { PropertyContext } from "../context/property";

function useSubmitWithToast({
  func,
  successMessage = "",
  errorMessage = "",
  successFunc = () => {},
  errorFunc = () => {},
  finallyFunc = () => {},
  keepLoading = false,
  startLoading = true,
  loadingText = null,
}: {
  func: any;
  successMessage?: string;
  errorMessage?: string;
  successFunc?: any;
  errorFunc?: any;
  finallyFunc?: any;
  keepLoading?: boolean;
  startLoading?: boolean;
  loadingText?: any;
}) {
  const { addToast } = useToasts();
  const { setLoading } = useContext(PropertyContext);

  const submit = (props: any) => {
    startLoading && setLoading(loadingText || true);
    func(props)
      .then((result: any) => {
        successFunc(result);
        if (!keepLoading) {
          setLoading(false);
        }
        successMessage &&
          addToast(successMessage, {
            appearance: "success",
            autoDismiss: true,
          });
      })
      .catch((err: any) => {
        errorFunc(err);
        console.error(err);
        addToast(
          `${errorMessage && `${errorMessage} - `}${String(err?.message)}`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        setLoading(false);
      })
      .finally(() => {
        finallyFunc();
      });
  };

  return [submit];
}

export default useSubmitWithToast;
