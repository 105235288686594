import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { PropertyContext, PropertyProvider } from "../context/property";
import Login from "./Login";
import Loading from "../components/Loading";
import Housekeeping from "./Housekeeping";
import Profile from "./Profile";
import useSubmitWithToast from "../action/useSubmitWithToast";
import { findStaffId } from "../action/actionStaff";
import { useHistory, useLocation } from "react-router-dom";
import Inspection from "./Inspection";
import Task from "./Task";

export const StaffPortalEntrance = ({ match }: { match: any }) => {
  const { isLoading, property } = useContext(PropertyContext);

  // useEffect(() => {}, [property]);

  return (
    <PropertyProvider match={match}>
      {isLoading && <Loading />}
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray-500 transition-opacity" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <PropertyEntrance />
          </div>
        </div>
      </div>
    </PropertyProvider>
  );
};

const PropertyEntrance = () => {
  const [email, setEmail] = useState("");
  const { property, propertyId } = useContext(PropertyContext);
  const history = useHistory();

  const { name: propertyName, avatar } = property || {};

  const [handleVerifyEmail] = useSubmitWithToast({
    func: () => {
      return findStaffId({ propertyId, search: email });
    },
    successMessage: "A verification code is sent to your email inbox.",
    successFunc: (result: any) => {
      const staffId = result?.staffId;
      const link = `/${propertyId}/staff/${staffId}?method=email`;
      history.push(link);
    },
  });

  return (
    <div>
      <div className="mt-3 text-center sm:mt-5">
        <img
          className="block h-20 rounded w-auto mb-5 mx-auto"
          src={avatar}
          alt={propertyName}
        />
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {propertyName}
        </h3>
        <hr className="my-8" />
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Staff Portal
        </h3>
        <div className="mt-10">
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter your email address"
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            onClick={handleVerifyEmail}
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

function StaffPortalWrapper({ match }: { match: any }) {
  const propertyId = match.params.propertyId;
  const staffId = match.params.staffId;
  const tabId = match.params.tabId;

  const queryURL = useLocation().search;
  const query = queryString.parse(queryURL);
  const method = query?.method || "";

  return (
    <PropertyProvider match={match}>
      <StaffPortal
        tabId={tabId}
        propertyId={propertyId}
        staffId={staffId}
        method={method}
      />
    </PropertyProvider>
  );
}

const StaffPortal = ({
  tabId,
  propertyId,
  staffId,
  method,
}: {
  tabId: string;
  propertyId: string;
  staffId: string;
  method?: any;
}) => {
  const { isLoggedIn } = useContext(PropertyContext);

  return (
    <>
      {isLoggedIn === null ? (
        <Loading />
      ) : isLoggedIn === true ? (
        <div className="min-h-screen bg-white">
          <Staff tabId={tabId} propertyId={propertyId} staffId={staffId} />
        </div>
      ) : isLoggedIn === false ? (
        <Login propertyId={propertyId} staffId={staffId} method={method} />
      ) : (
        <Loading />
      )}
    </>
  );
};

const Staff = ({
  tabId,
  propertyId,
  staffId,
}: {
  tabId: string;
  propertyId: string;
  staffId: string;
}) => {
  const { property, staff, isLoading } = useContext(PropertyContext);

  const { name: propertyName } = property || {};

  const { portalAvailability, portalStatements } = staff || {};

  const navigation = [
    { name: "Profile", href: "profile", current: true },
    { name: "Housekeeping", href: "housekeeping", current: false },
    { name: "Inspection", href: "inspection", current: false },
    { name: "Task", href: "task", current: false },
  ];

  return (
    <>
      <Helmet>
        <title>{`Staff Portal - ${propertyName}`}</title>
      </Helmet>

      {isLoading && <Loading />}

      <Header
        tabId={tabId}
        url={`/${propertyId}/staff/${staffId}/`}
        navigation={navigation}
        staff={staff}
        property={property}
      />
      <div className="py-10 min-h-screen bg-gray-100">
        {tabId === "housekeeping" && (
          <Housekeeping propertyId={propertyId} staffId={staffId} />
        )}

        {tabId === "profile" && (
          <Profile propertyId={propertyId} staffId={staffId} staff={staff} />
        )}

        {tabId === "inspection" && (
          <Inspection propertyId={propertyId} staffId={staffId} />
        )}

        {tabId === "task" && <Task propertyId={propertyId} staffId={staffId} />}
      </div>

      <Footer property={property} />
    </>
  );
};

export default StaffPortalWrapper;
