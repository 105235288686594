import axios from "axios";
import { getCodeCache } from "./actionAuth";

const uri = process.env.REACT_APP_PORTAL_URI;
const apiUsername = process.env.REACT_APP_API_USERNAME || "";
const apiPassword = process.env.REACT_APP_API_PASSWORD || "";

const auth = {
  username: apiUsername,
  password: apiPassword,
};

export const getOwnerDetails = async ({
  propertyId,
  ownerId,
  code,
}: {
  propertyId: string;
  ownerId: string;
  code: string;
}) => {
  const url = `${uri}/owner/`;
  const params = {
    propertyId,
    accountId: ownerId,
    code,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const getOwnerStatements = async ({
  propertyId,
  ownerId,
}: {
  propertyId: string;
  ownerId: string;
}) => {
  const code = getCodeCache();
  const url = `${uri}/owner/statements/`;
  const params = {
    propertyId,
    accountId: ownerId,
    code,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const getRoomDetails = async ({
  propertyId,
  roomId,
  ownerId,
}: {
  propertyId: string;
  roomId: string;
  ownerId: string;
}) => {
  const code = getCodeCache();
  const url = `${uri}/owner/room/`;
  const params = {
    propertyId,
    roomId,
    accountId: ownerId,
    code,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const createReservation = async ({
  reservation,
  propertyId,
  ownerId,
  code,
}: {
  reservation: any;
  propertyId: string;
  ownerId: string;
  code: string;
}) => {
  const url = `${uri}/owner/create-reservation`;
  const data = {
    propertyId,
    accountId: ownerId,
    code,
    reservation,
  };
  try {
    const result = await axios.post(url, JSON.stringify(data), { auth });

    return result.data;
  } catch (error: any) {
    throw Error(error?.response?.data);
    // throw Error(error.message);
  }
};

export const cancelReservation = async ({
  reservationId,
  propertyId,
  ownerId,
}: {
  reservationId: string;
  propertyId: string;
  ownerId: string;
}) => {
  const code = getCodeCache();
  const url = `${uri}/owner/cancel-reservation`;
  const data = {
    propertyId,
    accountId: ownerId,
    code,
    reservationId,
  };
  try {
    const result = await axios.post(url, JSON.stringify(data), { auth });

    return result.data;
  } catch (error: any) {
    throw Error(error?.response?.data?.message);
    // throw Error(error.message);
  }
};

export const getOwnerVerificationMethods = async ({
  propertyId,
  ownerId,
}: {
  propertyId: string;
  ownerId: string;
}) => {
  const url = `${uri}/owner/code`;
  const params = {
    propertyId,
    accountId: ownerId,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error) {}
};

export const sendCode = async ({
  propertyId,
  ownerId,
  method,
}: {
  propertyId: string;
  ownerId: string;
  method: string;
}) => {
  const url = `${uri}/owner/send-code`;
  const params = {
    propertyId,
    accountId: ownerId,
    method,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
};

export const verifyCode = async ({
  propertyId,
  ownerId,
  verificationCode,
}: {
  propertyId: string;
  ownerId: string;
  verificationCode: string;
}) => {
  const url = `${uri}/owner/verify-code`;
  const params = {
    propertyId,
    accountId: ownerId,
    verificationCode,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    throw Error(error.message);
  }
};

export const findOwnerId = async ({
  propertyId,
  search,
}: {
  propertyId: string;
  search: string;
}) => {
  const url = `${uri}/owner/find-owner-id`;
  const params = {
    propertyId,
    search,
  };

  try {
    const result = await axios.get(url, { params, auth });

    return result.data;
  } catch (error: any) {
    throw Error(error?.response?.data?.message || error.message);
  }
};
