import { useContext, useEffect, useState } from "react";
import { PaperClipIcon } from "@heroicons/react/solid";
import { getOwnerStatements } from "../action/actionOwner";
import { EmptyState } from "../components";
import { displayTimestamp } from "../action";
import { PropertyContext } from "../context/property";

export default function Statements({
  propertyId,
  ownerId,
}: {
  propertyId: string;
  ownerId: string;
}) {
  const { account } = useContext(PropertyContext);
  const [files, setFiles] = useState([]);
  const [filter, setFilter] = useState("All");
  const { portalStatements } = account || {};

  useEffect(() => {
    const handleFiles = async () => {
      const files2 = await getOwnerStatements({ propertyId, ownerId });
      setFiles(files2);
    };
    handleFiles();
  }, [propertyId, ownerId]);

  const filesToShow = files?.filter((file) => {
    const { isOwnerStatement, ledgerEntryId } = file;
    if (filter === "All") {
      return true;
    }
    if (filter === "Statements") {
      return !!isOwnerStatement;
    }
    if (filter === "Attachments") {
      return !!ledgerEntryId;
    }
    return false;
  });

  const counts = files?.reduce(
    (sum: any, file: any) => {
      const { isOwnerStatement, ledgerEntryId } = file;

      sum.All += 1;

      if (!!isOwnerStatement) {
        sum.Statements += 1;
      }
      if (!!ledgerEntryId) {
        sum.Attachments += 1;
      }
      return sum;
    },
    {
      All: 0,
      Statements: 0,
      Attachments: 0,
    }
  );

  const tabs = [
    { name: "All", href: "#", count: counts.All },
    {
      name: "Statements",
      href: "#",
      count: counts.Statements,
      hide: !portalStatements,
    },
    {
      name: "Attachments",
      href: "#",
      count: counts.Attachments,
      hide: portalStatements !== "attachments",
    },
  ];

  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            Statements
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 py-8 sm:px-0">
            <FilterTabs tabs={tabs} filter={filter} setFilter={setFilter} />
            {filesToShow?.length > 0 ? (
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200 bg-white">
                  {filesToShow
                    .sort((a: any, b: any) =>
                      a?.timestampAdded?._seconds > b?.timestampAdded?._seconds
                        ? -1
                        : 1
                    )
                    .map((file) => {
                      const { id, filename, name, url, timestampAdded } = file || {};

                      return (
                        <li
                          key={id}
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <div className="w-0 flex-1 flex items-center">
                            <PaperClipIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>
                              <span className="ml-2 flex-1 w-0 truncate">
                                {name || filename}
                              </span>
                              <span className="ml-2 flex-1 w-0 truncate text-gray-400 text-xs">
                                {displayTimestamp(timestampAdded, "DD/MM/YYYY")}
                              </span>
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <a
                              target="_blank"
                              href={url}
                              rel="noreferrer"
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                            >
                              Download
                            </a>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </dd>
            ) : (
              <EmptyState text="No Statements Found" />
            )}
          </div>
        </div>
      </main>
    </>
  );
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const FilterTabs = ({
  tabs,
  filter,
  setFilter,
}: {
  tabs: any[];
  filter: string;
  setFilter: any;
}) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={"All"}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          {tabs
            .filter((tab) => !tab.hide)
            .map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
        </select>
      </div>
      <div className="hidden sm:block mb-2">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => {
              const isSelected = filter === tab.name;
              const isHidden = tab.hide;

              if (!!isHidden) {
                return null;
              }

              return (
                <a
                  key={tab.name}
                  href="#"
                  onClick={() => setFilter(tab.name)}
                  className={classNames(
                    isSelected
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={isSelected ? "page" : undefined}
                >
                  {tab.name}
                  {tab.count ? (
                    <span
                      className={classNames(
                        isSelected
                          ? "bg-indigo-100 text-indigo-600"
                          : "bg-gray-100 text-gray-900",
                        "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                      )}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </a>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};
